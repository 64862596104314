export const ex5 = {
    titleL:{
        EN:'Sight @ Kuk Po',
        繁:'色@谷埔',
        簡:'色@谷埔',
    },
    titleR:{
        EN:'Exhibition',
        繁:'復育展覽',
        簡:'復育展览',
    },
    post1Title:{
        EN:'LEE’s Mansion Revitalisation Exhibition',
        繁:'田心李氏大宅復育展覽',
        簡:'田心李氏大宅復育展览',
    },
    post1Text:{
        EN:['This action research project “From Valley to Plain: Phase II” , funded by CCFS, and led by the Chinese University of Hong Kong, restores the 90-year-old hybrid mansion and regenerates the surrounding farmlands, so as to co-create with stakeholders a comprehensive setting for eco-living experiences in Hong Kong’s countryside, aspiring towards a sustainable and living culture. The hybrid mansion will be open to public and exhibit the project outcomes.'],
        繁:['由香港中文大學領導的跨學科行動研究「遊谷探埔II」透過修復 90 多年歷史的李氏混合式大宅及恢復周邊農地，與持分者共創多方面的保育行動鄉郊保育計劃資助，並試驗及推動綠色可持續的生活體驗。','李氏混合式大宅屆時將開放予公眾參觀及展示項目成果。'],
        簡:['由香港中文大学领导的跨学科行动研究「游谷探埔II」透过修復 90 多年历史的李氏混合式大宅及恢復周边农地，与持分者共创多方面的保育行动乡郊保育计划资助，并试验及推动绿色可持续的生活体验。','李氏混合式大宅届时将开放予公众参观及展示项目成果。'],
    },
    post1Date1:{
        EN:'18/1(Sat), 19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
        簡:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time1:{
        EN:'12:00 noon – 5:00 pm',
        繁:'中午12:00 – 下午5:00',
        簡:'中午12:00 – 下午5:00',
    },
    post1Loca1:{
        EN:'Lee’s Mansion, Tin Sum',
        繁:'田心李氏大宅',
        簡:'田心李氏大宅',
    },
}