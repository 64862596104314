import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M10.75 41.09h19.73c5.523 0 10-4.477 10-10V10.75c0-5.523-4.477-10-10-10H10.75c-5.523 0-10 4.477-10 10v20.34c0 5.523 4.477 10 10 10Z"
        opacity={0.5}
      />
      <path
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M10.75 41.09h19.73c5.523 0 10-4.477 10-10V10.75c0-5.523-4.477-10-10-10H10.75c-5.523 0-10 4.477-10 10v20.34c0 5.523 4.477 10 10 10Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={6}
        d="M21.41 33.18 8.94 20.7 20.97 8.66M14.6 20.92h17.69"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h41.23v41.84H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
