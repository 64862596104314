import '../App.css';
import { about } from '../content/about';
import { homeContent } from '../content/home';
import Navbar from '../conponents/Navbar'
//
import LogoSvg from '../svgs/LogoSvg'
import ShortDivider from '../conponents/ShortDivider'
import { useNavigate } from 'react-router-dom';
import { s3_PREFIX } from '../config';

function App({lang,handleSetRightNav}) {
    const ab = about
    const navigate = useNavigate();
    function navigating(str){ navigate(str) }
  return (
    <div className='relative flex flex-col w-full text-[black] h-full bg-[#01A334]'>
        {/*header*/}
        {/* <div className='w-full bg-white flex flex-row justify-between items-center' style={{borderBottom:'18px solid #01A334', zIndex: 99}}>
            <Navbar lang={lang} top={true} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
        </div> */}
        <Navbar sticky={true} lang={lang} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
        {/*header*/}
        <div className='w-full'>
            <img className='w-full' src={s3_PREFIX+'/images/aboutbg.jpg'} />
            <div className='flex flex-col items-center p-page'>
                <div className='home-title text-nowrap border text-[#ffffff] border-[#ffffff] border-[4px] rounded-full w-full max-w-[600px] bg-[#01A334]'>{ab.title[lang]}</div>
                <ShortDivider width={'100%'} maxW={380} py={4} color={'#ffffff'}/>
            </div>
            <div className='bg-[#01A334]'>
                <div className='flex flex-col w-full p-page'>
                    <img className='w-[80px] self-end pl-[16px]' src={s3_PREFIX+'/images/home/hometree.png'} style={{objectFit:'contain'}}></img>
                    <div className='relative w-full flex row-col start-to-center justify-center p-concert' style={{gap:'58px'}}>
                        <div className='flex flex-1'>
                            <img src={s3_PREFIX+'/images/aboutimg.jpg'} className='w-full rounded-xl max-h-[630px]' style={{aspectRatio: 596/630, objectFit:'cover'}}></img>
                        </div>
                        <div className='flex flex-1 flex-col justify-between text-white'>
                            {ab.text[lang].map((word) => <div className='home-22-text text-justify mb-[24px]'>{word}</div>)}
                        </div>
                    </div>
                    <img className='w-[100px] self-start pr-[16px]' src={s3_PREFIX+'/images/home/homeflower.png'} style={{objectFit:'contain'}}></img>
                </div>
                <div className='relative w-full bg-[#01A334] mt-[128px]'>
                    <img className='w-full' src={s3_PREFIX+'/images/home/stack1.png'}></img>
                    <img className='w-full absolute top-0 left-0' src={s3_PREFIX+'/images/home/stack2.png'}></img>
                    <img className='w-full absolute top-0 left-0' src={s3_PREFIX+'/images/home/stack3.png'}></img>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
