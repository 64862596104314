import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={49}
    height={51}
    fill="none"
    viewBox="0 0 49 51"
    id={props.id}
    {...props}
  >
    <path
      fill="#41A044"
      d="M44.13 50.78c1.32 0 2.5-.6 3.27-1.54.61-.74.97-1.68.97-2.7V10.02c0-2.13-1.59-3.9-3.64-4.19V2.17c0-1.2-.97-2.17-2.17-2.17S40.4.97 40.4 2.17v3.61h-1.81V1.96A2.163 2.163 0 0 0 36.43.01c-.76 0-1.43.39-1.81.99-.22.34-.35.74-.35 1.18v3.61h-1.81V2.18c0-.58-.23-1.11-.6-1.49-.4-.42-.96-.67-1.57-.67-.61 0-1.14.25-1.54.64-.39.39-.63.93-.63 1.52v3.61h-1.81V2.18c0-.47-.15-.9-.4-1.25-.39-.56-1.04-.92-1.77-.92-1.06 0-1.95.77-2.13 1.79-.02.12-.03.25-.03.38v3.61h-1.81V2.18C20.17.98 19.2.01 18 .01s-2.17.97-2.17 2.17v3.61h-1.81V2.18c0-1.2-.97-2.17-2.17-2.17S9.68.98 9.68 2.18v3.61H7.87V2.18C7.87.98 6.9.01 5.7.01S3.53.98 3.53 2.18v3.67C1.53 6.19 0 7.94 0 10.03v36.52c0 2.34 1.91 4.24 4.24 4.24h39.87l.02-.01ZM41.52 2.17a1.05 1.05 0 1 1 2.1 0v9.11a1.05 1.05 0 1 1-2.1 0V2.17Zm-5.1-1.05a1.048 1.048 0 0 1 1.05 1.06v9.11a1.05 1.05 0 1 1-2.1 0V2.18a1.057 1.057 0 0 1 1.05-1.06Zm-5.09 1.05v9.11a1.05 1.05 0 1 1-2.1 0V2.17a1.05 1.05 0 1 1 2.1 0Zm-7.2-1.05c.12 0 .23.02.33.06.42.14.72.53.72.99v9.11c0 .58-.48 1.05-1.05 1.05-.57 0-1.05-.47-1.05-1.05V2.17c0-.3.12-.57.32-.76.19-.18.45-.3.73-.3v.01Zm-7.19 1.05a1.05 1.05 0 1 1 2.1 0v9.11a1.05 1.05 0 1 1-2.1 0V2.17Zm-6.15 0c0-.58.48-1.05 1.05-1.05.57 0 1.05.47 1.05 1.05v9.11c0 .58-.48 1.05-1.05 1.05-.57 0-1.05-.47-1.05-1.05V2.17Zm-6.14 0a1.05 1.05 0 1 1 2.1 0v9.11a1.05 1.05 0 1 1-2.1 0V2.17Zm-3.52 7.85c0-1.48 1.03-2.72 2.41-3.04v4.3a2.166 2.166 0 0 0 3.18 1.91c.59-.32 1.02-.9 1.13-1.59.01-.11.02-.21.02-.32V6.89h1.81v4.39c0 1.19.97 2.16 2.17 2.16s2.17-.97 2.17-2.16V6.89h1.81v4.39c0 1.19.97 2.16 2.17 2.16s2.17-.97 2.17-2.16V6.89h1.81v4.39a2.164 2.164 0 0 0 4.33 0V6.89h1.81v4.39c0 1.19.97 2.16 2.17 2.16s2.17-.97 2.17-2.16V6.89h1.81v4.39a2.164 2.164 0 0 0 4.33 0V6.89h1.81v4.39a2.164 2.164 0 0 0 4.33 0V6.95c1.44.29 2.52 1.55 2.52 3.07v8.03H1.13v-8.03Zm3.12 39.65c-1.72 0-3.13-1.4-3.13-3.13V19.16h46.13v27.38c0 1.73-1.4 3.13-3.13 3.13H4.25Z"
    />
    <path
      fill="#fff"
      d="M47.25 18.05v-8.03c0-1.52-1.09-2.78-2.52-3.07v4.33a2.166 2.166 0 0 1-4.33 0V6.89h-1.81v4.39a2.166 2.166 0 0 1-4.33 0V6.89h-1.81v4.39c0 1.19-.97 2.16-2.17 2.16s-2.17-.97-2.17-2.16V6.89H26.3v4.39a2.166 2.166 0 0 1-4.33 0V6.89h-1.81v4.39c0 1.19-.97 2.16-2.17 2.16s-2.17-.97-2.17-2.16V6.89h-1.81v4.39c0 1.19-.97 2.16-2.17 2.16s-2.17-.97-2.17-2.16V6.89H7.86v4.39c0 .11 0 .22-.02.32-.11.69-.54 1.27-1.13 1.59a2.172 2.172 0 0 1-3.18-1.91v-4.3a3.121 3.121 0 0 0-2.41 3.04v8.03h46.13Z"
    />
    <path
      fill="#fff"
      stroke="#41A044"
      strokeMiterlimit={10}
      strokeWidth={0.9}
      d="M12.17 25.3H8.76c-1.01 0-1.83.82-1.83 1.83v3.41c0 1.01.82 1.83 1.83 1.83h3.41c1.01 0 1.83-.82 1.83-1.83v-3.41c0-1.01-.82-1.83-1.83-1.83ZM26.07 25.3h-3.41c-1.01 0-1.83.82-1.83 1.83v3.41c0 1.01.82 1.83 1.83 1.83h3.41c1.01 0 1.83-.82 1.83-1.83v-3.41c0-1.01-.82-1.83-1.83-1.83ZM39.36 25.3h-3.41c-1.01 0-1.83.82-1.83 1.83v3.41c0 1.01.82 1.83 1.83 1.83h3.41c1.01 0 1.83-.82 1.83-1.83v-3.41c0-1.01-.82-1.83-1.83-1.83ZM12.17 36.47H8.76c-1.01 0-1.83.82-1.83 1.83v3.41c0 1.01.82 1.83 1.83 1.83h3.41c1.01 0 1.83-.82 1.83-1.83V38.3c0-1.01-.82-1.83-1.83-1.83ZM26.07 36.47h-3.41c-1.01 0-1.83.82-1.83 1.83v3.41c0 1.01.82 1.83 1.83 1.83h3.41c1.01 0 1.83-.82 1.83-1.83V38.3c0-1.01-.82-1.83-1.83-1.83ZM39.36 36.47h-3.41c-1.01 0-1.83.82-1.83 1.83v3.41c0 1.01.82 1.83 1.83 1.83h3.41c1.01 0 1.83-.82 1.83-1.83V38.3c0-1.01-.82-1.83-1.83-1.83Z"
    />
  </svg>
)
export default SvgComponent
