import { useNavigate } from "react-router-dom"
import { homeContent } from "../content/home"
import Back from '../svgs/Back'
function Component({lang, path}){
    const navigate = useNavigate()
    function navigating(str){
        navigate(str)
    }
    return (<div className='w-full px-[21px] flex flex-row items-center justify-start cursor-pointer' onClick={() => {navigating(path)}}>
    <div className='flex flex-row items-center border border-[#035115] px-[13px] py-[4px] rounded-xl' style={{gap:8}}>
        <Back />
        <div className='text-[#035115] font-bold'>{homeContent.back[lang]}</div>
    </div>
</div>)
}

export default Component