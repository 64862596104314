export const ex7 = {
    titleL:{
        EN:'Sight @ Kuk Po',
        繁:'色@谷埔',
        簡:'色@谷埔',
    },
    titleR:{
        EN:'Exhibition',
        繁:'復育展覽',
        簡:'復育展览',
    },
    post1Title:{
        EN:'YEUNG’s Mansion Revitalisation Exhibition in Ng To',
        繁:'五肚楊氏大宅復育展覽',
        簡:'五肚杨氏大宅復育展览',
    },
    post1Text:{
        EN:['Funded by CCFS, the Chinese University of Hong Kong restores a village house and repurposes it as an Interdisciplinary Education Research Base at Ng To, Kuk Po, which pioneers a village conservation partnership between university and village community. The research base will be open to public and exhibit the research outcomes including Kuk Po’s landscape from inland valley to the coastal plain through the Kuk Po River, and the rich natural and cultural heritages of the 300-year-old Hakka settlements.'],
        繁:['在鄉郊保育計劃資助下，香港中文大學成功把谷埔五肚的村屋修復並活化成跨學科教研基地，開創大學與村落在谷埔的夥伴合作模式，探索可持續鄉村復育的各種方法。','基地屆時將對公眾開放，展覽包括從山谷經河流至平原及海岸的生態環境，與三百多年客家聚落人文地景的關係演變的研究成果。'],
        簡:['在乡郊保育计划资助下，香港中文大学成功把谷埔五肚的村屋修復并活化成跨学科教研基地，开创大学与村落在谷埔的伙伴合作模式，探索可持续乡村復育的各种方法。','基地届时将对公众开放，展览包括从山谷经河流至平原及海岸的生态环境，与三百多年客家聚落人文地景的关係演变的研究成果。'],
    },
    post1Date1:{
        EN:'18/1(Sat), 19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
        簡:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time1:{
        EN:'12:00 noon – 5:00 pm',
        繁:'中午12:00 – 下午5:00',
        簡:'中午12:00 – 下午5:00',
    },
    post1Loca1:{
        EN:'Yeung’s Mansion, Ng To',
        繁:'五肚楊氏大宅',
        簡:'五肚杨氏大宅',
    },
}