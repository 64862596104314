import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={32}
    fill="none"
    {...props}
  >
    <path
      stroke="#01A334"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={6}
      d="M15.47 28.43 3 15.95 15.03 3.91M8.66 16.17h17.69"
    />
  </svg>
)
export default SvgComponent
