import { homeContent } from '../content/home';
import LogoSvg from '../svgs/LogoSvg';
import ShortDivider from '../conponents/ShortDivider'
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../conponents/Navbar'
import '../App.css'
import { s3_PREFIX } from '../config';

const sec4Arr = [
    {
        img: '/images/home/sec4-1.jpg',
        path: '/concerts'
    },
    {
        img: '/images/home/sec4-2.jpeg',
        path: '/performance'
    },
    {
        img: '/images/home/sec4-3.png',
        path: '/art-installations'
    },
    {
        img: '/images/home/sec4-4.jpg',
        path: '/photo-exhibition'
    },
    {
        img: '/images/home/sec4-5.jpg',
        path: '/performance-parade'
    },
    {
        img: '/images/home/sec4-6.jpeg',
        path: '/market'
    },
]

const hc = homeContent
export default function Page(props){
    const lang= props.lang
    const navigate = useNavigate();
    const location = useLocation()
 
    function navigating(str){
        navigate(str)
    }

    return(
        <div className="w-full h-full flex flex-col justify-start bg-[#D0D6D0]">
            {/*header*/}
            {/* <div className='w-full bg-white flex flex-row justify-between items-center' style={{borderBottom:'18px solid #01A334'}}>
                <Navbar lang={lang} top={true} handleSetRightNav={(bol) => props.handleSetRightNav(bol)}/>
            </div> */}
            <Navbar sticky={true} lang={lang} handleSetRightNav={(bol) => props.handleSetRightNav(bol)}/>

            {/*header*/}

            <div className='w-full flex justify-center items-center relative' style={{height:undefined,aspectRatio:3840/726}}>
                <img src={s3_PREFIX+'/images/eventhighlights/deco2.png'} className='w-full' style={{height:undefined,aspectRatio:3840/726, objectFit:'contain'}} alt='bg'/>
                <div className='absolute flex flex-col max-w-[484px] w-full items-center p-page' style={{}}>
                    <div className='d-hidden-m-flex w-full h-[24px]'/>
                    <div className='home-title text-nowrap border border-[#01A334] border-[4px] rounded-full w-full max-w-[484px] bg-[#E4E9E6]'>{hc.sec4Title[lang]}</div>
                    <ShortDivider width={'100%'} maxW={380} py={4} color={'#01A334'}/>
                </div>
            </div>

            <div className='w-full flex-row flex items-center justify-center z-[2]'>
                <div className='relative flex w-full flex-col items-center pt-[24px] max-w-[1200px] '>
                    <div className='w-full p-page'>
                        <div className='w-full flex row-row flex-wrap justify-between items-start'>
                            {sec4Arr?.map((obj,i) => <div key={'sec4' + i} className='flex flex-col items-center justify-between mb-[124px] d-w-31-m-100 cursor-pointer village_ex_h' onClick={()=>{navigating(`${obj?.path}?lang=${props.lang}`)}}>
                                <img className='w-[100%] rounded-2xl' src={s3_PREFIX+obj.img} style={{height:undefined,aspectRatio: 4/3, objectFit:'cover'}}></img>
                                <div className='mt-[51px] title-text-1 text-[#FF685B]'>{hc[`sec4Title${i+1}`][lang]}</div>
                                <div className='mt-[16px] home-25 border text-white bg-[#01A334] rounded-full min-w-[170px] px-[16px] py-[4px]'>{
                                    [0,1].includes(i) ? hc.sec4BtnSound[lang]
                                    : [2,3,4].includes(i) ? hc.sec4BtnColor[lang]
                                    : hc.sec4BtnTaste[lang]
                                }{hc.sec4BtnEnd[lang]}</div>
                            </div>)}
                        </div>
                    </div>
                    <div className='w-full p-page my-[40px] home-22-text'>
                        {homeContent.special1[lang]}
                    </div>
                </div>
            </div>

            <div className='w-full flex justify-center items-center relative mt-[-10%] z-[1]'>
                <img src={s3_PREFIX+'/images/eventhighlights/deco1.png'} className='w-full' style={{height:undefined,aspectRatio:3840/1568, objectFit:'contain'}} alt='bg'/>
            </div>
        </div>
    )
}