export const achnow = {
    title:{
        EN:'About Us',
        繁:'關於我們',
        簡:'关于我们',
    },
    title2:{
        EN:'Countryside Conservation Office',
        繁:'鄉郊保育辦公室',
        簡:'乡郊保育办公室',
    },
    text:{
        EN:'Following the announcement in the 2017 Policy Address, the Countryside Conservation Office (CCO) was established under the Environmental Protection Department in end July 2018 and has been transferred to the Environment Branch of the Environment and Ecology Bureau with effect from 1 January 2023. \r\n\r\n As a priority, the CCO will enhance countryside revitalisation in Lai Chi Wo, as well as ecological conservation in Sha Lo Tung, and will keep under review the implementation of these initiatives and their effectiveness from time to time, with the aim of extending the initiatives progressively to other remote countryside areas. Since the establishment of the CCO, its staff have carried out a number of site inspections in Lai Chi Wo and Sha Lo Tung, and have been liaising and co-ordinating with relevant government departments, non-governmental organisations and stakeholders (including village representatives, the Rural Committees concerned and green groups, etc.) to discuss and examine suitable minor improvement works. The CCO will consult the Advisory Committee on Countryside Conservation about the suitable improvement works proposals, with a view to taking forward the proposals as soon as possible to improve the local infrastructure. \r\n\r\n The CCO will use the funding of $1 billion earmarked by the Government for undertaking relevant conservation and revitalisation efforts, as well as minor improvement works. The CCO will support collaboration of local non-profit-making organisations and villagers based on an interactive and co-operative approach under the Countryside Conservation Funding Scheme established with half of the $1 billion mentioned above, i.e. $500 million, under a non-recurrent account. The CCO will spend the remaining $500 million on suitable minor improvement works and rehabilitation of the existing architectural environment in the countryside.',
        繁:'根據《行政長官 2017年施政報告》公布中，鄉郊保育辦公室（鄉郊辦）於2018年7月底在環境保護署下成立，並於2023年1月1日起由環境及生態局環境科接掌。\r\n\r\n鄉郊辦會優先處理及深化荔枝窩的鄉郊復育工作，和推行沙羅洞的生態保育，並且會不時檢視工作的推行和成效，以期逐步把工作推展至其他偏遠鄉郊地區。自鄉郊辦成立以來，其人員已多次前往荔枝窩及沙羅洞作實地視察，聯絡及協調各有關政府部門、非政府機構及持份者（包括村代表、相關鄉事委員會和環保團體等），商討及研究合適的小型改善工程。鄉郊辦會就合適的改善工程建議諮詢鄉郊保育諮詢委員會，以期盡快推展，改善當地的基礎設施。\r\n\r\n鄉郊辦將利用政府預留的10億元資金，進行相關的保育及活化工作和小型改善工程。鄉郊辦會利用當中一半資金（即5億元）在非經常開支項目下，成立鄉郊保育資助計劃，支援本地非牟利機構和村民互動協作。鄉郊辦會利用餘下5億元，進行合適的小型改善工程及復育鄉郊現有建築環境。',
        簡:'根据《行政长官 2017年施政报告》公布中，乡郊保育办公室（乡郊办）于2018年7月底在环境保护署下成立，并于2023年1月1日起由环境及生态局环境科接掌。\r\n\r\n乡郊办会优先处理及深化荔枝窝的乡郊復育工作，和推行沙罗洞的生态保育，并且会不时检视工作的推行和成效，以期逐步把工作推展至其他偏远乡郊地区。自乡郊办成立以来，其人员已多次前往荔枝窝及沙罗洞作实地视察，联络及协调各有关政府部门、非政府机构及持份者（包括村代表、相关乡事委员会和环保团体等），商讨及研究合适的小型改善工程。乡郊办会就合适的改善工程建议谘询乡郊保育谘询委员会，以期尽快推展，改善当地的基础设施。\r\n\r\n乡郊办将利用政府预留的10亿元资金，进行相关的保育及活化工作和小型改善工程。乡郊办会利用当中一半资金（即5亿元）在非经常开支项目下，成立乡郊保育资助计划，支援本地非牟利机构和村民互动协作。乡郊办会利用馀下5亿元，进行合适的小型改善工程及復育乡郊现有建筑环境。',
    },
    suport:{
        EN:'Supporting Organisation (in no particular order)',
        繁:'支持機構 (排名不分先後)',
        簡:'支持机构 (排名不分先后)',
    },
    text2:{
        EN:'Representatives and villagers of Kuk Po Village',
        繁:'谷埔村代表及一眾村民',
        簡:'谷埔村代表及一众村民',
    }
}