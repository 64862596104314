export const vilEx = {
    title:{
        EN:'Village Experience',
        繁:'鄉郊體驗',
        簡:'乡郊体验',
    },
    title1:{
        EN:'Drinking in the Moonlight',
        繁:'月下獨酌——賞茶工作坊',
        簡:'月下独酌——赏茶工作坊',
    },
    title2:{
        EN:'Moonlight Storyteller - Screening Session',
        繁:'月下說書人——放映分享會',
        簡:'月下说书人——放映分享会',
    },
    title3:{
        EN:'Glow in the Dark (Simple Ball Game)',
        繁:'谷埔夜光盃(簡單球類競技遊戲)',
        簡:'谷埔夜光盃(简单球类竞技游戏)',
    },
    title4:{
        EN:'Kai Choi School Exhibition “Art Kuk Po”',
        繁:'啟才學校藝術展覽',
        簡:'启才学校艺术展览',
    },
    title5:{
        EN:'LEE’s Mansion Revitalisation Exhibition in Tin Sum',
        繁:'田心李氏大宅復育展覽',
        簡:'田心李氏大宅復育展览',
    },
    title6:{
        EN:'HO’s Collections of Agricultural Tools & Objects',
        繁:'何氏農具古物收藏屋',
        簡:'何氏农具古物收藏屋',
    },
    title7:{
        EN:'YEUNG’s Mansion Revitalisation Exhibition in Ng To',
        繁:'五肚楊氏大宅復育展覽',
        簡:'五肚杨氏大宅復育展览',
    },
    title8:{
        EN:'Lo Wai Community Garden – Demonstration of Micro-renewal Restoration Experiment',
        繁:'老圍社區花園——微建築修復實驗展示',
        簡:'老围社区花园——微建筑修復实验展示',
    },
    sec4BtnEnd:{
        EN:'@ Kuk Po',
        繁:'@谷埔',
        簡:'@谷埔',
    },
    sec4BtnSound:{
        EN:'Sound',
        繁:'聲',
        簡:'声',
    },
    sec4BtnColor:{
        EN:'Sight',
        繁:'色',
        簡:'色',
    },
    sec4BtnTaste:{
        EN:'Taste',
        繁:'味',
        簡:'味',
    },
}