import '../App.css';
import {useRef, useEffect, useState } from 'react';
import Navbar from '../conponents/Navbar'
import ShortDivider from '../conponents/ShortDivider'

import { ex5 } from '../content/villageEx5';
import { homeContent } from '../content/home';
import EventTable from '../conponents/EventTable'
import LogoSvg from '../svgs/LogoSvg';
import { useNavigate } from 'react-router-dom';
import Back from '../conponents/Backbtn'
import { s3_PREFIX } from '../config';

const cc = ex5

function Component({lang, handleSetRightNav}) {
    const navigate = useNavigate()
    function navigating(str){
        navigate(str)
    }
    return(<div className='relative flex flex-col w-full text-[black] h-full bg-[#E4E9E6]'>
            {/*header*/}
            <div className='absolute top-0 left-0 w-full max-h-[100vh] overflow-hidden'>
                <img className='w-full' src={s3_PREFIX+'/images/exbgwave.png'} style={{objectFit:'cover'}}></img>
            </div>            
            {/* <div className='w-full bg-white flex flex-row justify-between items-center' style={{borderBottom:'18px solid #01A334', zIndex: 99}}>
                <Navbar lang={lang} top={true} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
            </div> */}
            <Navbar sticky={true} lang={lang} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
            {/*header*/}
        <div className='flex flex-col w-full items-center py-[48px]' style={{zIndex:2}}>
            <Back lang={lang} path={`/village-experience?lang=${lang}`}/>
            {/* <div className='flex row-col start-to-center justify-center w-full p-concert mt-[59px]' style={{gap: 117}}>
                <div className='flex flex-1 items-center justify-center w-full max-w-[545px]'>
                    <div className='home-18 font-bold text-white bg-[#01A334] rounded-full w-full max-w-[545px] text-nowrap h-[69px] flex items-center justify-center'>{cc.titleL[lang]}</div>
                </div>
                <div className='flex flex-1 flex-col items-center'>
                    <div className='px-[136px] home-title border border-[4px] border-[#01A334] rounded-full w-full max-w-[484px] text-nowrap'>{cc.titleR[lang]}</div>
                    <ShortDivider color={'#01A334'} width={'100%'} maxW={380}/>
                </div>
            </div> */}
            <div className='flex row-col items-center justify-center w-full p-concert mt-[59px]' style={{gap: 117}}>
                <div className='home-25 font-bold text-white bg-[#01A334] rounded-full text-nowrap h-[69px] flex items-center justify-center px-[36px]'>
                    {cc.titleL[lang]}
                </div>  
            </div>
            {/* post start */}
            <div className='flex row-col justify-between w-full p-concert mt-[48px]' style={{gap: 117}}>
                <div className='flex flex-1 flex-col'>
                    <div>
                        <img className='w-[100%] rounded-xl' src={s3_PREFIX+'/images/vilex/vil_ex_5.jpg'} style={{height:undefined, aspectRatio:4/3, objectFit:'cover'}}></img>
                    </div>
                    {cc?.specialTitle?.[lang] && <div className='flex py-[16px] flex-col bg-white rounded-xl justify-center items-center mt-[16px]' style={{gap:14,border:'2px solid #99DFAD'}}>
                        <div className='relative'>
                            <div className='text-[#008735] home-22 relative text-start px-[12px]' style={{zIndex:999,whiteSpace:'pre-wrap'}}>{cc.specialTitle[lang]}</div>
                            <div className='w-[100%] h-[16px] absolute bottom-0 bg-[#99DFAD80]' style={{zIndex:1}}></div>
                        </div>
                        <div className='text-justify'>{cc.specialText1[lang]}</div>
                        <div className='text-justify'>{cc.specialText2[lang]}</div>
                    </div>}
                </div>
                <div className='flex flex-1 flex-col items-start'>
                    <div className='relative w-[80%]'>
                        <div className='relative w-full'>
                            <div className='text-[#008735] title-text-1 relative text-start' style={{zIndex:999}}>{cc.post1Title[lang]}</div>
                            <div className='w-[100%] h-[16px] absolute bottom-0 bg-[#ffffff]' style={{zIndex:1}}></div>
                        </div>
                    </div>
                    {typeof cc.post1Text[lang] === 'object' ? 
                        cc.post1Text[lang].map((text) => <div className='text-justify mt-[31px] home-22-text'>{text}</div>)
                    :
                        cc.post1Text[lang]
                    }
                    {/* <div className='text-justify mt-[31px] home-22-text'>{cc.post1Text[lang]}</div> */}
                    <EventTable style={{marginTop: 48}} date={cc.post1Date1[lang]} time={cc.post1Time1[lang]} loca1={cc.post1Loca1[lang]}/>
                    <div className='h-[64px]'></div>             
                </div>
            </div>
            {/* post end */}

        </div>
    </div>)
}

export default Component