export const perPride = {
    titleL:{
        EN:'Sight @ Kuk Po',
        繁:'色@谷埔',
        簡:'色@谷埔',
    },
    titleR:{
        EN:'Performance and Parade',
        繁:'表演及巡遊',
        簡:'表演及巡游',
    },
    post1Title:{
        EN:'Luminescence Unicorn Performance and Parade',
        繁:'夜光麒麟表演及巡遊',
        簡:'夜光麒麟表演及巡游',
    },
    post1Text:{
        EN:'The Unicorn, a symbol of auspiciousness and community spirit in Hakka culture, will take center stage at the festival. Master Wan Kin-on, a renowned dragon, lion, and unicorn dance artist, has crafted Sha Tau Kok’s first luminous Unicorn. Watch as it dances through the village, bringing festive cheer while passing on traditional skills to the next generation.',
        繁:'麒麟是客家的瑞獸，有吉祥和凝聚村落之意。龍獅麒麟貔貅教練兼藝術家温健安師傅為谷埔創作沙頭角首隻夜光麒麟，觀看瑞獸穿越村落翩翩起舞，帶來節日的歡愉，同時將傳統技藝傳承給新一代。',
        簡:'麒麟是客家的瑞兽，有吉祥和凝聚村落之意。龙狮麒麟貔貅教练兼艺术家温健安师傅为谷埔创作沙头角首隻夜光麒麟，观看瑞兽穿越村落翩翩起舞，带来节日的欢愉，同时将传统技艺传承给新一代。',
    },
    post1Date1:{
        EN:'18/1(Sat)',
        繁:'18/1(六)',
        簡:'18/1(六)',
    },
    post1Time1:{
        EN:'6:00 pm - 7:00 pm (1 hour)',
        繁:'下午6:00 – 7:00 (1小時)',
        簡:'下午6:00 – 7:00 (1小时)',
    },
    post1Loca1:{
        EN:'Kai Choi School',
        繁:'啟才學校',
        簡:'启才学校',
    },
    post1Date2:{
        EN:'26/1(Sun)',
        繁:'26/1(日)',
        簡:'26/1(日)',
    },
    post1Time2:{
        EN:'6:00 pm - 7:00 pm (1 hour)',
        繁:'下午6:00 – 7:00 (1小時)',
        簡:'下午6:00 – 7:00 (1小时)',
    },
    post1Loca2:{
        EN:'Kai Choi School, San Wai, Tin Sum, Hoi Ha etc.',
        繁:'啟才學校、新圍、田心、海下等',
        簡:'启才学校、新围、田心、海下等',
    },
    post1Little:{
        EN:'Luminescence Unicorn Performance',
        繁:'夜光麒麟表演',
        簡:'夜光麒麟表演',
    },
    post1Big:{
        EN:'Luminescence Unicorn Parade (Kuk Po Team)',
        繁:'夜光麒麟巡遊(谷埔隊)',
        簡:'夜光麒麟巡游(谷埔队)',
    },
}