export const ballContent = {
    titleL:{
        EN:'Sight @ Kuk Po',
        繁:'色@谷埔',
        簡:'色@谷埔',
    },
    titleR:{
        EN:'Simple Ball Game',
        繁:'球類競技遊戲',
        簡:'球类竞技游戏',
    },
    post1Title:{
        EN:'Glow in the Dark (Simple Ball Game)',
        繁:'谷埔夜光盃 (簡單球類競技遊戲)',
        簡:'谷埔夜光盃 (简单球类竞技游戏)',
    },
    post1Text:{
        EN:['We recreate the electrifying energy of the World Cup under Kuk Po’s pitch-black sky.','A fun and simple ball game awaits, 8 teams (5 members each) compete in 3 rounds of elimination matches under the glow of the luminous field The winning team will receive a custom-made glowing cup, shining with victory in the dark. Or, if you prefer, join as a spectator, cheering on the teams and sharing in the excitement.'],
        繁:['我們利用谷埔漆黑的夜空，重現當日世界盃的熱鬧。','這是一場簡單又有趣的球類競技遊戲。參加者將會分成8個隊伍(每組5人)進行3輪淘汰賽，穿梭在夜光球場上。勝出的隊伍，將獲得特製獎品，在黑夜中，一起發光發亮。你也可以成為觀眾，在旁吶喊打氣，共同分享喜悅。'],
        簡:['我们利用谷埔漆黑的夜空，重现当日世界盃的热闹。','这是一场简单又有趣的球类竞技游戏。参加者将会分成8个队伍(每组5人)进行3轮淘汰赛，穿梭在夜光球场上。胜出的队伍，将获得特製奖品，在黑夜中，一起发光发亮。你也可以成为观众，在旁呐喊打气，共同分享喜悦。'],
    },
    post1Date1:{
        EN:'19/1(Sun)、25/1(Sat)、26/1(Sun)',
        繁:'19/1(日)、25/1(六)、26/1(日)',
        簡:'19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time1:{
        EN:'5:00 pm – 7:00 pm',
        繁:'下午5:00 – 7:00',
        簡:'下午5:00 – 7:00',
        
    },
    post1Loca1:{
        EN:'San Wai',
        繁:'新圍',
        簡:'新围',
        
    },
    specialTitle:{
        EN:'Distributing physical tickets onsite\r\nfrom 1:00 pm to 5:00 pm',
        繁:'活動當日下午1時至5時現場派籌',
        簡:'活动当日下午1时至5时现场派筹',
        
    },
    specialText1:{
        EN:'(40 tickets per day)',
        繁:'(每日40個名額)',
        簡:'(每日40个名额)',
        
    },
    specialText2:{
        EN:'First come first served!',
        繁:'先到先得，額滿即止',
        簡:'先到先得，额满即止',
        
    },
}