export const homeContent = {
    transportRemark:{
        EN:`Please stay tuned to the Countryside Conservation Office's social media announcements for the information on the latest arrangements for the event.`,
        繁:'備註：詳情及時間以運輸署網站及九巴網站公布為準。',
        簡:'备註：详情及时间以运输署网站及九巴网站公布为准。',
    },
    special1: {
        EN:`Please stay tuned to the Countryside Conservation Office's social media announcements for the information on the latest arrangements for the event.`,
        繁:'有關活動的最新安排，請密切留意鄉郊保育辦公室社交媒體的公布，以獲取最新資訊。',
        簡:'有关活动的最新安排，请密切留意乡郊保育办公室社交媒体的公布，以获取最新资讯。',
    },
    backTop: {
        EN:'back to top',
        繁:'回到頂部',
        簡:'返回',
        
    },
    back: {
        EN:'Back',
        繁:'返回',
        簡:'返回',
    },
    navOpenBtn: {
        EN:'Menu',
        繁:'選單',
        簡:'选单',
    },
    topNavTicket: {
        EN:'Transportation',
        繁:'交通安排',
        簡:'交通安排',
    },
    topNavMap: {
        EN:'Map',
        繁:'地圖',
        簡:'地图',
    },
    topNavTimetable: {
        EN:'Timetable and Map',
        繁:'時間表及地圖',
        簡:'时间表及地图',
    },
    topNavEvent: {
        EN:'Event Hightlights',
        繁:'亮點節目',
        簡:'亮点节目',
    },
    download:{
        EN:'Download',
        繁:'下載',
        簡:'下载',
    },
    zoomin:{
        EN:'Zoom',
        繁:'放大',
        簡:'放大',
    },
    sec1Title:{
        EN:'Discover the Countryside Harvest Festival',
        繁:'鄉郊豐年節故事',
        簡:'乡郊丰年节故事',
    },
    sec1Text1:{
        EN:['Step into a place where nature meets culture! The Countryside Harvest Festival invites you to explore Hong Kong’s hidden treasures—featuring natural ecology, built heritage, and vibrant cultural experiences that make for a truly unique and immersive tour.',],
        繁:['香港鄉郊蘊藏豐富瑰寶，','鄉郊豐年節串連起自然生態、','鄉村歷史建築及人文風情，','推動嶄新的深度生態遊體驗。'],
        簡:['香港乡郊蕴藏丰富瑰宝，','乡郊丰年节串连起自然生态、','乡村历史建筑及人文风情，','推动崭新的深度生态游体验。'],
    },
    sec1Text2:{
        EN:['Start your journey at Kuk Po Village in Sha Tau Kok—wander through mangroves, snap shots of built heritage, and vibe with “Sound-Sight-Taste” activities that bring this unique village to life.'],
        繁:['讓我們率先帶你走進沙頭角谷埔村，','在紅樹林、蘆葦叢與歷史建築之間，','展開一場「聲—色—味」之旅。'],
        簡:['让我们率先带你走进沙头角谷埔村，','在红树林、芦苇丛与历史建筑之间，','展开一场「声—色—味」之旅。'],
    },
    sec1DateTitle:{
        EN:'Date: ',
        繁:'日期：',
        簡:'日期：',
    },
    sec1TimeTitle:{
        EN:'Time: ',
        繁:'時間：',
        簡:'时间：',
    },
    sec1LocaTitle:{
        EN:'Location: ',
        繁:'地點：',
        簡:'地点：',
    },
    sec1DateText1:{
        EN:'18, 19, 25, 26 January 2025  (Events)',
        繁:'2025年1月18、19、25、26日 (活動)',
        簡:'2025年1月18、19、25、26日 (活动)',
    },
    sec1DateText2:{
        EN:'18 January – 16 February 2025 (Exhibition)',
        繁:'2025年1月18日至2月16日 (展覽)',
        簡:'2025年1月18日至2月16日 (展览)',
    },
    sec1TimeText:{
        EN:'12:00 noon – 7:00 pm',
        繁:'中午12:00 – 下午7:00',
        簡:'中午12:00 – 下午7:00',
    },
    sec1LocaText:{
        EN:'Kuk Po, Sha Tau Kok ',
        繁:'沙頭角谷埔',
        簡:'沙头角谷埔',
    },
    // section 2
    sec2Title:{
        EN:'Timetable and Map',
        繁:'時間表及地圖',
        簡:'时间表及地图',
    },
    sec2Time:{
        EN:'Timetable',
        繁:'時間表',
        簡:'时间表',
    },
    sec2Map:{
        EN:'Map',
        繁:'地圖',
        簡:'地图',
    },
    // Section 3
    sec3Title:{
        EN:'Transportation',
        繁:'交通/預留船票',
        簡:'交通/预留船票',
    },
    sec3BoatTitle1:{
        EN:'Land Transportation',
        繁:'陸路',
        簡:'陆路',
    },
    sec3BoatTitle2:{
        EN:'Water Transportation',
        繁:'水路',
        簡:'水路',
    },
    sec3BoatText1:{
        EN:[`Visitors may take Green Minibus route 56K to Luk Keng Terminus from Fanling MTR Station Exit C. `,`Alight at Luk Keng Terminus and walk along Bride's Pool Road towards Kai Kuk Shue Ha, then proceed on foot to Kuk Po. `,`This walk takes approximately 1 hour to Kuk Po.`],
        繁:['於粉嶺乘搭56K專線小巴到鹿頸下車，沿山路經過雞谷樹下及鳳坑，步行約1小時，便可到達谷埔。'],
        簡:['于粉岭乘搭56K专线小巴到鹿颈下车，沿山路经过鸡谷树下及凤坑，步行约1小时，便可到达谷埔。'],
    },
    sec3BoatText2:{
        EN:['Taking a bus or green minibus to Sha Tau Kok, then a boat to Kuk Po Pier (about 10 minutes).','(For access to closed areas, a valid permit is required)'],
        繁:['先乘搭巴士或專線小巴前往沙頭角，再乘小艇到谷埔碼頭(船程約10分鐘)。','(需持有有效邊境禁區許可證)'],
        簡:['先乘搭巴士或专线小巴前往沙头角，再乘小艇到谷埔码头(船程约10分钟)。','(需持有有效边境禁区许可证)'],
    },
    sec3BoatText3:{
        EN:'[ Ticket reservation system will be released at 10:00 on 18 Dec 2024 ]',
        繁:'[ 預留船票系統將於12月18日上午10:00開放 ]',
        簡:'[ 预留船票系统将于12月18日上午10:00开放 ]',
        
    },
    sec3BoatBtn:{
        EN:'Apply Closed Area Permit',
        繁:'申請邊境禁區許可證',
        簡:'申请边境禁区许可证',
    },
    sec3TicketTitle:{
        EN:'Special Transportation Arrangement',
        繁:'特別交通安排',
        簡:'特别交通安排',
    },
    sec3TicketText:{
        EN:'To make it easier for visitors to join the event, free boat services will be provided on designated event days, shuttling between Sha Tau Kok and Kuk Po. Each trip offers 500 seats per direction, so be sure to reserve your spot early—limited seats available!',
        繁:'主辦單位於指定活動日安排免費船隻，分時段接載遊人往來沙頭角及谷埔，去程及回程各500個名額，方便遊人參與活動。名額有限，敬請及早預留船票。',
        簡:'主办单位于指定活动日安排免费船隻，分时段接载游人往来沙头角及谷埔，去程及回程各500个名额，方便游人参与活动。名额有限，敬请及早预留船票。',
    },
    sec3TicketDateData:{
        EN:'18, 19, 25, 26 January 2025 (Saturdays & Sundays)',
        繁:'2025年1月18、19、25、26日 (星期六及日)',
        簡:'2025年1月18、19、25、26日 (星期六及日)',
    },
    sec3TicketTimeData:{
        EN:[`To Kuk Po: 12:00 noon – 5:00 pm (Departing from Sha Tau Kok Public Pier)` , `To Sha Tau Kok: 2:20 pm – 8:00 pm (Departing from Kuk Po Pier)`,],
        繁:['(去程往谷埔) 中午12:00 - 下午5:00','(回程往沙頭角) 下午2:20 - 8:00',],
        簡:['(去程往谷埔) 中午12:00 - 下午5:00','(回程往沙头角) 下午2:20 - 8:00',],
        
    },
    sec3TicketLocaData:{
        EN:[''],
        繁:['(去程往谷埔)   沙頭角公眾碼頭',' (回程往沙頭角) 谷埔碼頭'],
        簡:['(去程往谷埔)   沙头角公众码头',' (回程往沙头角) 谷埔码头'],
        
    },
    sec3TicketLocaData2:{
        EN:['Follow staff instructions to queue and board according to your reserved time. Don’t miss your chance to experience the countryside!'],
        繁:['請按照預定時間，依照工作人員指示排隊上船',],
        簡:['请按照预定时间，依照工作人员指示排队上船',],
        
    },
    sec3TicketBuy:{
        EN:'Click here to reserve your ferry ticket',
        繁:'按此留船票',
        簡:'按此留船票',
        
    },
    // Section 3
    sec4Title:{
        EN:'Event Highlights',
        繁:'亮點節目',
        簡:'亮点节目'
        
    },
    sec4Title1:{
        EN:'Countryside Melodies Outdoor Concert at Kuk Po',
        繁:'「鄉郊樂韻」谷埔戶外音樂會',
        簡:'「乡郊乐韵」谷埔户外音乐会',
        
    },
    sec4Title2:{
        EN:'Performance into the Wild',
        繁:'原野劇場',
        簡:'原野剧场',
        
    },
    sec4Title3:{
        EN:'Kuk Po Art Exhibition – Find the Lights',
        繁:'谷埔藝術展覽––看見',
        簡:'谷埔艺术展览––看见'
    },
    sec4Title4:{
        EN:'"A Stroll through Kuk Po: The Beauty of Return” Photo Exhibition',
        繁:'「回——谷埔走一回」攝影展',
        簡:'「回——谷埔走一回」摄影展',
    },
    sec4Title5:{
        EN:'Luminous Unicorn Performance and Parade',
        繁:'夜光麒麟表演及巡遊',
        簡:'夜光麒麟表演及巡游', 
    },
    sec4Title6:{
        EN:'Good Day Market',
        繁:'豐年農集',
        簡:'丰年农集',
    },
    sec4BtnEnd:{
        EN:'@ Kuk Po',
        繁:'@谷埔',
        簡:'@谷埔', 
    },
    sec4BtnSound:{
        EN:'Sound',
        繁:'聲',
        簡:'声',  
    },
    sec4BtnColor:{
        EN:'Sight',
        繁:'色',
        簡:'色', 
    },
    sec4BtnTaste:{
        EN:'Taste',
        繁:'味',
        簡:'味',
    },

    remarks:{
        EN:'Important Notes:',
        繁:'備註：',
        簡:'备註：',
    },
    remarks1:{
        EN:['1. A valid Frontier Closed Area Permit (CAP) is required to board. ',
            '2. Tickets are free for both adults and children. Please book in advance and present the QR code and CAP when boarding.', 
            '3. Participants are kindly advised to arrive at the boarding location according to the scheduled time for efficient crowd management and to minimise waiting time.',
        ],
        繁:['(1)須持有有效邊境禁區許可證方可上船。','(2)成人及小童一人一票，費用全免。敬請預先預留船票，按照預定時間，在登船前出示有關二維碼。','(3)請各位參加者按照預約時間到達上船位置，以便有效分流，避免輪候時間過長。'],
        簡:['(1)须持有有效边境禁区许可证方可上船。','(2)成人及小童一人一票，费用全免。敬请预先预留船票，按照预定时间，在登船前出示有关二维码。','(3)请各位参加者按照预约时间到达上船位置，以便有效分流，避免轮候时间过长。'],
    },
    photoRef:{
        EN:'Photo for reference only',
        繁:'相片僅供參考',
        簡:'相片仅供参考',        
    },
    aiPhoto:{
        EN:`Artist's impression for illustrative purpose only`,
        繁:'僅供說明的構想圖',
        簡:'仅供说明的构想图',        
    },
    

}