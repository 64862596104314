import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M30.48.75H10.75c-5.523 0-10 4.477-10 10v20.34c0 5.523 4.477 10 10 10h19.73c5.523 0 10-4.477 10-10V10.75c0-5.523-4.477-10-10-10Z"
        opacity={0.5}
      />
      <path
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M30.48.75H10.75c-5.523 0-10 4.477-10 10v20.34c0 5.523 4.477 10 10 10h19.73c5.523 0 10-4.477 10-10V10.75c0-5.523-4.477-10-10-10Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={6}
        d="m19.82 8.66 12.47 12.48-12.03 12.04M26.63 20.92H8.94"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M41.23 41.84H0V0h41.23z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
