import { Routes, Route, useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import {useRef, useEffect, useState } from 'react';
import { footerContent } from '../content/Footer';
import '../App.css'
import './nav.css'
import { s3_PREFIX } from '../config';
function Footer({lang}){
    const navigate = useNavigate();
    const location = useLocation()
 
    function navigating(str){
        navigate(str)
    }

    const fc = footerContent
    return(<div className='w-full h-[311px] min-h-[311px] bg-[#ffffff] border-t-[32px] border-[#01A334] flex row-col-rev d-p41-m-p14' style={{zIndex: 9999, gap:14}}>
        <div className='flex flex-col items-start' style={{gap:14}}>
            <div className='flex flex-row items-center justify-center select-none text-nowrap flex-wrap' style={{gap:18}}>
                <div className='footer-18 text-[#676767] cursor-pointer' onClick={()=>{navigating(`/?lang=${lang}`)}}>{fc.foot1[lang]}</div>
                <div className='w-[1px] h-[16px] bg-[#676767]'></div>
                <div className='footer-18 text-[#676767] cursor-pointer' onClick={()=>{navigating(`/event-highlights?lang=${lang}`)}}>{fc.foot2[lang]}</div>
                <div className='w-[1px] h-[16px] bg-[#676767]'></div>
                <div className='footer-18 text-[#676767] cursor-pointer' onClick={()=>{navigating(`/village-experience?lang=${lang}`)}}>{fc.foot3[lang]}</div>
                <div className='w-[1px] h-[16px] bg-[#676767]'></div>
                <div className='footer-18 text-[#676767] cursor-pointer' onClick={()=>{navigating(`/about?lang=${lang}`)}}>{fc.foot4[lang]}</div>
                <div className='w-[1px] h-[16px] bg-[#676767]'></div>
                <div className='footer-18 text-[#676767] cursor-pointer' onClick={()=>{navigating(`/acknowledgement?lang=${lang}`)}}>{fc.foot5[lang]}</div>
            </div>
            <div className='flex flex-row' style={{gap: 21}}>
                <a target='_blink' href={'https://www.facebook.com/countrysideconservationhk/'}>
                    <img src={s3_PREFIX+'/icon-fc.png'}></img>
                </a>
                <a target='_blink' href={'https://www.instagram.com/countrysideconservationhk/'}>
                    <img src={s3_PREFIX+'/icon-insta.png'}></img>
                </a>
                <a target='_blink' href={'https://youtube.com/@countrysideconservationhk?si=ZfC5XAEa--CpOEQm'}>
                    <img src={s3_PREFIX+'/icon-utube.png'}></img>
                </a>
            </div>
            <div className='text-[10px] text-[#676767]'>All rights reserved © 2024 </div>
        </div>
        <div className='flex flex-row items-center justify-around w-full max-w-[600px]' style={{gap: 21}}>
            <div className='w-[127px]'>
                <img className='w-full' src={s3_PREFIX+'/logo-w.png'} style={{aspectRatio:127/82, height:undefined}}></img>
            </div>
            <div className='flex flex-col items-start w-[155px]'>
                <div className='text-[12px] text-[#676767]'>{fc.footR1[lang]}</div>
                <img className='w-[155px]' src={s3_PREFIX+'/logo-cco.png'} style={{aspectRatio:155/85, height:undefined}}></img>
            </div>
            <div className='flex flex-col items-start'>
                <div className='text-[12px] text-[#676767]'>{fc.footR2[lang]}</div>
                <img className='w-[152px]' src={s3_PREFIX+'/logo-poly.png'} style={{aspectRatio:155/86, height:undefined}}></img>
            </div>
        </div>
    </div>)
}

export default Footer;
