import { useNavigate } from 'react-router-dom';
import { homeContent } from '../content/home';
import LogoSvg from '../svgs/LogoSvg';
import Navbar from '../conponents/Navbar'
export default function Page({lang,handleSetRightNav }){
    const navigate = useNavigate()
    function navigating(str){
        navigate(str)
    }
    return(
        <div className="w-full h-full flex flex-col justify-start">
            {/*header*/}
            {/* <div className='w-full bg-white p-[16px] flex flex-row justify-between items-center' style={{borderBottom:'18px solid #01A334'}}>
                <Navbar lang={lang} top={true} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
            </div> */}
            <Navbar sticky={true} lang={lang} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
            {/*header*/}

            
            <div className='flex-1 mt-[46px] font-bold'>Page Not Found</div>
        </div>
    )
}