export const marketContent = {
    titleL:{
        EN:'Taste @ Kuk Po',
        繁:'味@谷埔',
        簡:'味@谷埔',
    },
    titleR:{
        EN:'Market',
        繁:'市集',
        簡:'市集',
    },
    post1Title:{
        EN:'Good Day Market',
        繁:'豐年農集',
        簡:'丰年农集',
    },
    post1Text:{
        EN:'The Market brings together villagers and farmers from different villages to share rural stories through local produce, speciality foods and Hakka crafts. ',
        繁:'豐年農集結合來自各區村民、農戶，以本地農作物、特色食物、客家傳統工藝講述鄉村故事。',
        簡:'丰年农集结合来自各区村民、农户，以本地农作物、特色食物、客家传统工艺讲述乡村故事。',
    },
    post1Date1:{
        EN:'18/1(Sat), 19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
        簡:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time1:{
        EN:'12:00 noon – 5:00 pm',
        繁:'中午12:00 – 下午5:00',
        簡:'中午12:00 – 下午5:00',
    },
    post1Loca1:{
        EN:'San Wai',
        繁:'新圍',
        簡:'新围',
    },
}