import { homeContent } from '../content/home';
import LogoSvg from '../svgs/LogoSvg';
import ShortDivider from '../conponents/ShortDivider'
import { useLocation, useNavigate } from 'react-router-dom';
import { ArtInstallationsContent } from '../content/ArtInstallation';
import { useState } from 'react';
import Navbar from '../conponents/Navbar'
//
import LeftButton from '../svgs/LeftButton'
import RightButton from '../svgs/RightButton'
import EventTable from '../conponents/EventTable';
import Calander from '../svgs/Calander'
import Clock from '../svgs/Clock'
import Back from '../conponents/Backbtn'
import { s3_PREFIX } from '../config';

const hc = homeContent
const content = ArtInstallationsContent
const PHOTOS = [
    '/images/artinstallations/photo1.png',
    '/images/artinstallations/photo2.jpg',
    '/images/artinstallations/photo3.jpg',
    '/images/artinstallations/photo4.jpg',
    '/images/artinstallations/photo5.jpeg',
]
export default function Page({lang, handleSetRightNav}){
    // const lang= props.lang
    const navigate = useNavigate();
    const location = useLocation()
 
    function navigating(str){
        navigate(str)
    }

    const [photoIndex,setphotoIndex]=useState(0)
    const handlePhotoScroll=(num)=>()=>{
        const newIndex = photoIndex+num
        setphotoIndex(newIndex<0?PHOTOS.length-1:newIndex>=PHOTOS.length?0:newIndex)
    }
    //banner drag
    const [dragPosition,setdragPosition]=useState(null)
    const handeDragEnd=(e)=>{
        const endPosition=e?.clientX;
        if(endPosition+50<dragPosition){
            handlePhotoScroll(1)()
        }else if(endPosition>dragPosition+50){
            handlePhotoScroll(-1)()
        }setdragPosition(null)
    }
    const handeDragStart=(e)=>{
        setdragPosition(e?.clientX||null)
    }

    return(
        <div className="relative w-full h-full flex flex-col justify-start bg-[#D0D6D0]">
            {/*header*/}
            {/* <div className='w-full bg-white flex flex-row justify-between items-center' style={{borderBottom:'18px solid #01A334'}}>
                <Navbar lang={lang} top={true} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
            </div> */}
            <Navbar sticky={true} lang={lang} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>

            {/*header*/}

            <div className='w-full flex justify-center items-center relative z-[1]'>
                <img src={s3_PREFIX+'/images/eventhighlights/deco2.png'} className='w-full' style={{height:undefined,aspectRatio:3840/726, objectFit:'contain'}} alt='bg'/>
            </div>

            <div className='flex flex-col w-full items-center justify-center mt-[-15vw] z-[2] p-[16px]'>
                <Back lang={lang} path={`/event-highlights?lang=${lang}`}/>
                <div className='flex flex-row w-full max-w-[1130px] items-center justify-center mt-[8px]' onDragStart={handeDragStart} onDragEnd={handeDragEnd} style={{overflow:'hidden'}}>
                    <div  className='relative w-full flex flex-row items-center cursor-pointer' style={{height:undefined,aspectRatio:4/3,background:"black",overflow:'hidden'}}>
                        <div className='top-[0%] flex flex-row absolute h-full' style={{width:`${PHOTOS.length*100}%`,transition:"1s all ease",left:`-${photoIndex*100}%`}}>
                            {PHOTOS.map((item,index)=>
                                <img src={s3_PREFIX+item} key={index}  className='relative h-full' style={{width:undefined,aspectRatio:4/3,objectFit:"cover"}} alt='arts'/>
                            )}
                        </div>
                        <div className='absolute cursor-pointer left-[16px]' onClick={handlePhotoScroll(-1)}>
                            <LeftButton/>
                        </div>
                        <div className='absolute cursor-pointer right-[16px]' onClick={handlePhotoScroll(1)}>
                            <RightButton/>
                        </div>
                    </div>
                
                </div>

                {/* <div className='flex flex-row w-full max-w-[1130px] items-start justify-between mt-[64px]'>
                    <div className='flex items-center justify-center w-full max-w-[545px]'>
                        <div className='home-22 font-bold text-white bg-[#01A334] rounded-full w-full max-w-[545px] text-nowrap h-[69px] flex items-center justify-center'>{content.titleL[lang]}</div>
                    </div>
                    <div className='flex flex-col max-w-[484px] w-full items-center p-page' style={{}}>
                        <div className='home-title text-nowrap border border-[#01A334] border-[4px] rounded-full w-full max-w-[484px] bg-[transparent]'>{content.titleR[lang]}</div>
                        <ShortDivider width={'100%'} maxW={380} py={4} color={'#01A334'}/>
                    </div>
                </div>  */}
                <div className='flex row-col items-center justify-center w-full p-concert mt-[59px]' style={{gap: 117}}>
                    <div className='home-25 font-bold text-white bg-[#01A334] rounded-full text-nowrap h-[69px] flex items-center justify-center px-[36px]'>
                        {content.titleL[lang]}
                    </div>  
                </div>
                

                {/*post 1*/}
                <div className='flex flex-col w-full max-w-[1130px] items-start justify-between mt-[64px] bg-[#FFFFFFCC] d-p-48-m-16'>
                    <div className='relative w-full'>
                        <div className='relative w-[80%]'>
                            <div className='text-[#008735] title-text-1 relative text-left' style={{zIndex:999}}>{content?.post1Title[lang]}</div>
                            <div className='w-[100%] max-w-[400px] h-[50%] absolute bottom-0 bg-[#99DFAD66]' style={{zIndex:1}}></div>
                        </div>
                    </div>
                    <div className='text-[black] home-22-text font-[400] relative mt-[24px]' style={{textAlign: 'justify'}}>{content?.post1Content[lang]}</div>

                    <div className='flex flex-row w-[90%] flex-wrap mt-[24px]' style={{gap:'16px'}}>
                        <a href='#post1'>
                            <div className='rounded-[99px] px-[16px] py-[6px]' style={{border:'2px solid #99DFAD'}}>{content?.post1Tag1[lang]}</div>
                        </a>
                        <a href='#post2'>
                            <div className='rounded-[99px] px-[16px] py-[6px]' style={{border:'2px solid #99DFAD'}}>{content?.post1Tag2[lang]}</div>
                        </a>
                        <a href='#post3'>
                            <div className='rounded-[99px] px-[16px] py-[6px]' style={{border:'2px solid #99DFAD'}}>{content?.post1Tag3[lang]}</div>
                        </a>
                        <a href='#post4'>
                            <div className='rounded-[99px] px-[16px] py-[6px]' style={{border:'2px solid #99DFAD'}}>{content?.post1Tag4[lang]}</div>
                        </a>
                        <a href='#post5'>
                            <div className='rounded-[99px] px-[16px] py-[6px]' style={{border:'2px solid #99DFAD'}}>{content?.post1Tag5[lang]}</div>
                        </a>
                        <a href='#post6'>
                            <div className='rounded-[99px] px-[16px] py-[6px]' style={{border:'2px solid #99DFAD'}}>{content?.post1Tag6[lang]}</div>
                        </a>
                        <a href='#post7'>
                            <div className='rounded-[99px] px-[16px] py-[6px]' style={{border:'2px solid #99DFAD'}}>{content?.post1Tag7[lang]}</div>
                        </a>
                        <a href='#post8'>
                            <div className='rounded-[99px] px-[16px] py-[6px]' style={{border:'2px solid #99DFAD'}}>{content?.post1Tag8[lang]}</div>
                        </a>
                        <a href='#post9'>
                            <div className='rounded-[99px] px-[16px] py-[6px]' style={{border:'2px solid #99DFAD'}}>{content?.post1Tag9[lang]}</div>
                        </a>
                    </div>
                    <div className='flex flex-row w-full flex-wrap justify-between mt-[48px]'>
                        <div className='mb-[16px]'>
                            <EventTable time={content?.post1Time?.[lang]} date={content?.post1Date?.[lang]} loca1={content?.post1Location?.[lang]}/>
                        </div>
                        <div className='w-[322px] flex flex-col rounded-[22px] items-center justify-between' style={{border:'2px solid #99DFAD',gap:8}}>
                            <div className='relative w-[100%] flex items-center justify-center py-[8px]' style={{borderBottom:'2px solid #99DFAD'}}>
                                <div className='text-[#008735] home-22 relative text-left' style={{zIndex:999}}>{content?.post1Sp1Title[lang]}</div>
                                <div className='w-[126px] h-[16px] absolute bottom-[8px] bg-[#99DFAD66]' style={{zIndex:1}}></div>
                            </div>
                            <div className='flex w-full flex-row items-center justify-start px-[16px]'>
                                <Calander style={{marginRight: 12,}}/>
                                <div className='home-22-text text-left text-wrap'>{content?.post1Sp1Date[lang]}</div>
                            </div>
                            <div className='flex w-full flex-row items-center justify-start mb-[16px] px-[16px]'>
                                <Clock  style={{marginRight: 12}}/>
                                <div className='home-22-text text-left text-wrap'>{content?.post1Sp1Time[lang]}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*post 1*/}

                {/*posts*/}
                {[2,3,4,5,6,7,8,9,10].map((item, i)=>
                    <div id={`post${i+1}`} key={item} className='relative flex flex-col w-full max-w-[1130px] items-start justify-between mt-[64px] bg-[#FFFFFFCC] d-p-48-m-16'>
                        <div className='relative w-full flex flex-row justify-end mt-[24px]'>
                            <div className='relative d-w-50-m-100'>
                                <div className='text-[#008735] title-text-1 relative text-left' style={{zIndex:999}}>{content?.[`post${item}Title`][lang]}</div>
                                <div className='w-[100%] max-w-[245px] h-[50%] absolute bottom-0 bg-[#99DFAD66]' style={{zIndex:1}}></div>
                            </div>
                        </div>
                        <div className='relative w-full flex flex-row justify-start mt-[24px] flex-wrap'>
                            <div className='relative d-w-50-m-100 d-pr-48-m-0 mb-[24px]'>
                                <img src={s3_PREFIX+content?.[`post${item}Img`][lang]} className='w-full rounded-[24px]' style={{height:undefined,aspectRatio:4/3,objectFit:"cover"}} alt='info'/>
                            </div>
                            <div className='relative d-w-50-m-100 flex flex-col'>
                                <div className='text-[black] home-22 font-[400] relative text-left'>{content?.[`post${item}Subtitle`][lang]}</div>
                                <div className='text-[black] home-22-text font-[400] relative mt-[24px]' style={{whiteSpace:'pre-wrap',textAlign:'justify'}}>{content?.[`post${item}Content`][lang]}</div>
                            </div>
                        </div>

                        {item===2&&<div className='flex items-center justify-center d-w-50-m-100 absolute top-[-35px] left-[0%]'>
                            <div className='home-25 font-bold text-white bg-[#01A334] rounded-full w-full text-nowrap h-[69px] flex items-center justify-center'>{content.post2Tag[lang]}</div>
                        </div>}
                    </div>
                )}
                {/*posts*/}



            </div>

            

     

            <div className='w-full flex justify-center items-center relative'>
                <img src={s3_PREFIX+'/images/eventhighlights/deco3.png'} className='w-full' style={{height:undefined,aspectRatio:3840/1568, objectFit:'contain'}} alt='bg'/>
            </div>
        </div>
    )
}