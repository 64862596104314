import './nav.css'
import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { s3_PREFIX } from '../config';

import { footerContent } from '../content/Footer';
function Component({rightNav, handleSetRightNav, lang, handleSetLang}) {
  const [delayNav, setDelayNav] = useState(false)
  const navigate = useNavigate();
  const location = useLocation()
  const navRef = useRef()
  const fc = footerContent
    useEffect(() => {
        console.log(rightNav)
        let timer = undefined
        if(!rightNav){
            timer = setTimeout(() => setDelayNav(rightNav), 200)
            setTimeout(() => {
                if(navRef.current)navRef.current.style.display = 'none'
            },200)
        }else{
            if(navRef.current)navRef.current.style.display = 'flex'
            setDelayNav(rightNav)
        }
    },[rightNav])
  function navigating(str){
    // setCurScreen(str)
    handleSetRightNav(false)
    navigate(str)
  }


  if(!delayNav) return <></>
  return (
    <div ref={navRef} className={'fixed top-0 left-0 w-[100vw] h-[100vh] ' + (rightNav ? 'fade-in-me' : 'fade-out-me')} style={{zIndex:999999}}>
        <div className='inset-0 absolute cursor-pointer' onClick={() => handleSetRightNav(false)}></div>
        <div>
            <div className={'absolute right-0 flex flex-col w-[30vw] h-[100vh] bg-[#01A334] pl-[81px] px-[50px] py-[44px] min-w-[404px] overflow-auto justify-between ' + (rightNav ? 'slide-in-right' : 'slide-out-right')} style={{zIndex:100, borderTopLeftRadius: 150}}>
                <div className='w-full flex flex-row justify-end select-none self-start'>
                    <div onClick={() => handleSetRightNav(false)} className='ursor-pointer w-[78px] h-[78px] rounded-full bg-[#ffffff] flex flex-col items-center justify-center cursor-pointer'>
                        <div className='flex flex-col mt-[20px]' style={{gap:4}}>
                            <img src={s3_PREFIX+'/close.png'}></img>
                        </div>
                        <div className='text-[12px] text-[#01A334] font-[500] mt-[6px]'>
                        {fc.close[lang]}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-start select-none' style={{gap:18}}>
                    <div className='nav-18 text-[#ffffff] cursor-pointer w-35-m-25' onClick={()=>{navigating(`/?lang=${lang}`)}}>{fc.foot1[lang]}</div>
                    <div className='nav-18 text-[#ffffff] cursor-pointer w-35-m-25' onClick={()=>{navigating(`/event-highlights?lang=${lang}`)}}>{fc.foot2[lang]}</div>
                    <div className='nav-18 text-[#ffffff] cursor-pointer w-35-m-25' onClick={()=>{navigating(`/village-experience?lang=${lang}`)}}>{fc.foot3[lang]}</div>
                    <div className='nav-18 text-[#ffffff] cursor-pointer w-35-m-25' onClick={()=>{navigating(`/about?lang=${lang}`)}}>{fc.foot4[lang]}</div>
                    <div className='nav-18 text-[#ffffff] cursor-pointer w-35-m-25 text-left' onClick={()=>{navigating(`/acknowledgement?lang=${lang}`)}}>{fc.foot5[lang]}</div>
                </div>
                <div>
                    <div className='flex flex-row items-center select-none' style={{gap: 12}}>
                        <a target='_blink' href={'https://www.facebook.com/countrysideconservationhk/'}>
                            <img className='w-[35px] h-[35px] cursor-pointer' src={s3_PREFIX+'/fcw.png'}></img>
                        </a>
                        <a target='_blink' href={'https://www.instagram.com/countrysideconservationhk/'}>
                            <img className='w-[35px] h-[35px] cursor-pointer' src={s3_PREFIX+'/instaw.png'}></img>
                        </a>
                        <a target='_blink' href={'https://youtube.com/@countrysideconservationhk?si=ZfC5XAEa--CpOEQm'}>
                            <img className='w-[35px] h-[35px] cursor-pointer' src={s3_PREFIX+'/utubew.png'}></img>
                        </a>
                    </div>
                    <div className='flex flex-row items-center mt-[35px] mb-[10vh]' style={{gap: 12}}>
                        <div onClick={() => {handleSetLang('EN');handleSetRightNav(false)}} className='w-30-m-18 font-[500] text-white select-none cursor-pointer'>EN</div>
                        <div onClick={() => {handleSetLang('繁');handleSetRightNav(false)}} className='w-30-m-18 font-[500] text-white select-none cursor-pointer'>繁</div>
                        <div onClick={() => {handleSetLang('簡');handleSetRightNav(false)}} className='w-30-m-18 font-[500] text-white select-none cursor-pointer'>簡</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Component