export const about = {
    title:{
        EN:'About Kuk Po',
        繁:'有關谷埔',
        簡:'有关谷埔',
    },
    text:{
        EN:[
            'Kuk Po is a Hakka village in Sha Tau Kok with nearly 400 years of history. The village offers a scenic view of Sha Tau Kok and Shenzhen’s Yantian District across the border and is situated near Luk Keng, Fung Hang, and Lai Chi Wo, which is also known for its golden reed fields and the Kai Choi School built in the 1930s. Starting in the late Ming and early Qing dynasties, various clans migrated south from northern regions to settle in Kuk Po, dispersed across the village. By the late Qing dynasty, the population exceeded 500, making it the second-largest village in Sha Tau Kok, and a critical transport hub along the Sha Tau Kok Inner Sea and the northeastern coast of Plover Cove.',
            'Kuk Po is rich in natural ecological resources, featuring a unique brackish wetland. In the past, villagers leveraged its coastal geography and natural resources, reclaiming land for farming, building dams along the shore for fish and shrimp farming, and sustaining livelihoods through rice cultivation and fishing. The area hosts diverse natural habitats, including feng shui woodlands, reed fields, mangroves, intertidal water ponds, mudflats, and marshes as well as a natural stream with significant ecological value. These features make Kuk Po a vital habitat for birds, fish, crabs, and various insects, highlighting its ecological value.',
            `The village’s built heritage seamlessly blends with its natural surroundings, creating a unique cultural landscape. Kuk Po features the Lo Wai, a traditional Hakka walled village, the elongated San Wai, and Grade III historic buildings such as Kai Choi School, Yeung Ancestral Hall and Li Ancestral Hall in Lo Wai.`,
            'Although most Kuk Po villagers migrated due to industrialisation and rapid urban development, both local and overseas villagers remain committed to the revitalisation of Kuk Po. Since 2021, with the support of the Countryside Conservation Funding Scheme under the Countryside Conservation Office (CCO), various revitalisation projects have been launched in Kuk Po. These initiatives cover Hakka culture, architecture, and multi-disciplinary research, attracting professors, scholars, and dedicated volunteers to engage in studies and exchanges within the village. Through the collaborative efforts, scattered villagers have been brought back to Kuk Po, fostering community reunions. CCO also assisted restaurants in the village to successfully apply and obtain restaurant licence to support eco-tourism and better serve visitors.',
        ],
        繁:[
            '谷埔是位於沙頭角的客家村落，有近四百年歷史，能遙望對岸的沙頭角和深圳鹽田區，毗鄰鹿頸、鳳坑、荔枝窩等，以金黃蘆葦田和三十年代建成的啟才學校聞名。自明末清初開始，各姓族群由北方南下，陸續遷入谷埔定居，聚居點分散於村內不同地方。清末時，谷埔人口超過500人，為沙頭角第二大的村落，也是沙頭角內海和船灣東北海岸線的重要交通要站。',
            '谷埔蘊含豐富的自然生態環境，擁有獨特的鹹淡水交界濕地。昔日谷埔村民充分利用這裡臨海地理及環境資源的優勢，圍海造田，亦在海邊築堤養魚和蝦，以種植水稻和捕魚維生。這裡擁有風水林、蘆葦叢、紅樹林、潮間池塘、泥灘、沼澤等多樣自然生境，以及一條具重要生態價值的天然河溪，使谷埔成為鳥類、魚蟹和昆蟲等多元物種的棲息地，極具生態價值。',
            '此外，谷埔的傳統建築與自然生態相融合，交織成為谷埔獨特的文化地境。谷埔除了具有客家方型圍村特徵的老圍和長條型的新圍外，還有被評為三級歷史建築的啟才學校、老圍的楊氏宗祠和李氏宗祠等。',
            '儘管在工業化和市區急速發展下，大部分谷埔村民經已外遷，但移居海外及本地的村民一直以來關注並致力於復育谷埔的工作。鄉郊辦透過「鄉郊保育資助計劃」支持非政府團體於谷埔推行多元化的復育項目，自2021年至今，已有多個項目獲得資助，涵蓋客家文化、客家建築及跨學科研究等範疇，吸引了不同學科的教授、學者及熱心義工在村內研究及交流，成功尋回散落各地的村民重聚谷埔，共同復育谷埔。鄉郊辦同時協助村內食肆成功申領食肆牌照，促進生態旅遊及利便訪客。',
        ],
        簡:[
            '谷埔是位于沙头角的客家村落，有近四百年历史，能遥望对岸的沙头角和深圳盐田区，毗邻鹿颈、凤坑、荔枝窝等，以金黄芦苇田和三十年代建成的启才学校闻名。自明末清初开始，各姓族群由北方南下，陆续迁入谷埔定居，聚居点分散于村内不同地方。清末时，谷埔人口超过500人，为沙头角第二大的村落，也是沙头角内海和船湾东北海岸线的重要交通要站。',
            '谷埔蕴含丰富的自然生态环境，拥有独特的咸淡水交界湿地。昔日谷埔村民充分利用这里临海地理及环境资源的优势，围海造田，亦在海边筑堤养鱼和虾，以种植水稻和捕鱼维生。这里拥有风水林、芦苇丛、红树林、潮间池塘、泥滩、沼泽等多样自然生境，以及一条具重要生态价值的天然河溪，使谷埔成为鸟类、鱼蟹和昆虫等多元物种的栖息地，极具生态价值。',
            '此外，谷埔的传统建筑与自然生态相融合，交织成为谷埔独特的文化地境。谷埔除了具有客家方型围村特徵的老围和长条型的新围外，还有被评为三级历史建筑的启才学校、老围的杨氏宗祠和李氏宗祠等。',
            '尽管在工业化和市区急速发展下，大部分谷埔村民经已外迁，但移居海外及本地的村民一直以来关注并致力于復育谷埔的工作。乡郊办透过「乡郊保育资助计划」支持非政府团体于谷埔推行多元化的復育项目，自2021年至今，已有多个项目获得资助，涵盖客家文化、客家建筑及跨学科研究等范畴，吸引了不同学科的教授、学者及热心义工在村内研究及交流，成功寻回散落各地的村民重聚谷埔，共同復育谷埔。乡郊办同时协助村内食肆成功申领食肆牌照，促进生态旅游及利便访客。',
        ],
    }
}