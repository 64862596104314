export const ex6 = {
    titleL:{
        EN:'Sight @ Kuk Po',
        繁:'色@谷埔',
        簡:'色@谷埔',
    },
    titleR:{
        EN:'Collections',
        繁:'古物收藏屋',
        簡:'古物收藏屋',
    },
    post1Title:{
        EN:'HO’s Collections of Agricultural Tools & Objects',
        繁:'何氏農具古物收藏屋',
        簡:'何氏农具古物收藏屋',
    },
    post1Text:{
        EN:['Mr. Ho, a resident of Kuk Po, has curated a fascinating collection of farming tools and equipment, including a restored weaving loom and rice seeds from Kuk Po dating back to the 1960s. He has established a private venue to display his collection, offering visitors a glimpse into the daily life of farmers in the past.'],
        繁:['谷埔村民何先生收藏不同谷埔的農具和器具，包括：村民復修的織布機、60年代谷埔的稻米種子等，並自資設立私人古物收藏屋，展示昔日農家的生活。'],
        簡:['谷埔村民何先生收藏不同谷埔的农具和器具，包括：村民復修的织布机、60年代谷埔的稻米种子等，并自资设立私人古物收藏屋，展示昔日农家的生活。'],
    },
    post1Date1:{
        EN:'18/1(Sat), 19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
        簡:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time1:{
        EN:'3:00 pm – 5:00 pm',
        繁:'下午3:00 – 5:00',
        簡:'下午3:00 – 5:00',
    },
    post1Loca1:{
        EN:'Ho’s Mansion, Tin Sum',
        繁:'田心何氏大宅側',
        簡:'田心何氏大宅侧',
    },
}