export const drinkingContent = {
    titleL:{
        EN:'Taste @ Kuk Po',
        繁:'味@谷埔',
        簡:'味@谷埔',
    },
    titleR:{
        EN:'Drinking',
        繁:'工作坊',
        簡:'工作坊',
    },
    post1Title:{
        EN:'Drinking in the Moonlight',
        繁:'月下獨酌——賞茶工作坊',
        簡:'月下独酌——赏茶工作坊',
    },
    post1Text:{
        EN:['Under the moonlit sky and a cool breeze, experience the essence of Kuk Po’s past while sipping on unique drinks served in handmade clay cups crafted by locals. Workshops feature ingredients like roselle and pomelo leaves, harvested right from Kuk Po’s soil, infusing each sip with the authentic flavours of the land. Together with handmade ceramic cups designed by village residents and local students, this is the true taste of Kuk Po.',],
        繁:['皓皓⽉⾊，清⾵朗⽉，品嚐⾕埔陶泥杯盛載的特⾊飲品，欣賞光影裝置，細述鄉村舊話。','⼯作坊⽤上⾕埔種的農作物，洛神花、柚葉等材料⼊茶，讓遊人可嚐到本地作物的香味。配合由村⺠和中學⽣創作的⾕埔陶泥器具，享受⾕埔的真正味道。'],
        簡:['皓皓⽉⾊，清⾵朗⽉，品嚐⾕埔陶泥杯盛载的特⾊饮品，欣赏光影装置，细述乡村旧话。','⼯作坊⽤上⾕埔种的农作物，洛神花、柚叶等材料⼊茶，让游人可嚐到本地作物的香味。配合由村⺠和中学⽣创作的⾕埔陶泥器具，享受⾕埔的真正味道。'],
    },
    post1Date1:{
        EN:'19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'19/1(日)、25/1(六)、26/1(日)',
        簡:'19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time1:{
        EN:'5:00 pm – 7:00 pm (25 minutes per session, 4 sessions per day)',
        繁:'下午5:00 – 7:00',
        簡:'下午5:00 – 7:00',
    },
    post1Loca1:{
        EN:'Grassland outside LEE’s Mansion, Tin Sum',
        繁:'田心李氏大宅外',
        簡:'田心李氏大宅外',
    },
    specialTitle:{
        EN:'Distributing physical tickets onsite from 1:00 pm to 5:00 pm',
        繁:'活動當日下午1時至5時現場派籌',
        簡:'活动当日下午1时至5时现场派筹',
    },
    specialText1:{
        EN:'(60 tickets per day)',
        繁:'(每日60個名額)',
        簡:'(每日60个名额)',
    },
    specialText2:{
        EN:'First come first served!',
        繁:'先到先得，額滿即止',
        簡:'先到先得，额满即止',
    },
}