export const concertContent = {
    titleL:{
        EN:'Sound @ Kuk Po',
        繁:'聲@谷埔',
        簡:'声@谷埔',
    },
    titleR:{
        EN:'Outdoor Concert',
        繁:'音樂表演',
        簡:'音乐表演',
    },
    post1Title:{
        EN:'Countryside Melodies Outdoor Concert at Kuk Po',
        繁:'「鄉郊樂韻」谷埔戶外音樂會',
        簡:'「乡郊乐韵」谷埔户外音乐会',
    },
    post1Text:{
        EN:'Feel the charm of Kuk Po come alive through the melodies of world- class musicians! Gordon Lee, the World Harmonica Festival champion, and internationally renowned musician Vincent Liauw will weave beautiful harmonies amidst the birds and flowers of Kuk Po. Enjoy the soulful voice of singer Karen Kong as it mingles with the breeze, bringing both nature and visitors into the rhythm of the countryside.',
        繁:'感受谷埔的魅力，在世界級音樂家的旋律中生動展現！世界口琴節冠軍李俊樂(Gordon)和國際知名音樂家廖原(Vincent)，將在谷埔的鳥語花香中編織出美麗的和聲。歌手龔柯允(Karen)的深情歌聲與微風交織，將大自然與遊人融入鄉間的韻律中。',
        簡:'感受谷埔的魅力，在世界级音乐家的旋律中生动展现！世界口琴节冠军李俊乐(Gordon)和国际知名音乐家廖原(Vincent)，将在谷埔的鸟语花香中编织出美丽的和声。歌手龚柯允(Karen)的深情歌声与微风交织，将大自然与游人融入乡间的韵律中。',
    },
    post1Date1:{
        EN:'18/1(Sat)',
        繁:'18/1(六)',
        簡:'18/1(六)',
    },
    post1Time1:{
        EN:'3:00 pm - 3:30 pm (30 minutes)',
        繁:'下午3:00 - 3:30 (30分鐘)',
        簡:'下午3:00 - 3:30 (30分钟)',
    },
    post1Loca1:{
        EN:'(1) Near the Breakwater Area (Outside Chung Kee Store) and (2) Gate near Annex Block of Kai Choi School',
        繁:'(1) 近堤壩 (松記士多外)、(2) 啟才學校附屬建築閘外',
        簡:'(1) 近堤坝 (松记士多外)、(2) 启才学校附属建筑闸外',
    },
    post1Loca1_2:{
        EN:'(Each time slot features two concurrent performances at separate locations)',
        繁:'(兩場表演同時於不同場地進行)',
        簡:'(两场表演同时于不同场地进行)',
    },
    post1Date2:{
        EN:'18/1(Sat), 19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
        簡:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time2:{
        EN:'3:00 pm - 3:30 pm (30 minutes)',
        繁:'下午3:00 - 3:30 (30分鐘)',
        簡:'下午3:00 - 3:30 (30分钟)',
    },
    post1Loca2:{
        EN:'(1) Near the Breakwater Area (Outside Chung Kee Store) and (2) Gate near Annex Block of Kai Choi School',
        繁:'(1) 近堤壩 (松記士多外)、(2) 啟才學校附屬建築閘外',
        簡:'(1) 近堤坝 (松记士多外)、(2) 启才学校附属建筑闸外',
    },
    post1Loca2_2:{
        EN:'(Each time slot features two concurrent performances at separate locations)',
        繁:'(兩場表演同時於不同場地進行)',
        簡:'(两场表演同时于不同场地进行)',
    },
    post1Date3:{
        EN:'18/1(Sat)',
        繁:'18/1(六)',
        簡:'18/1(六)',
    },
    post1Time3:{
        EN:'5:00 pm - 5:30 pm (30 minutes)',
        繁:'下午5:00 – 5:30 (30分鐘)',
        簡:'下午5:00 – 5:30 (30分钟)',
    },
    post1Loca3:{
        EN:'Kai Choi School',
        繁:'啟才學校',
        簡:'启才学校',
    },
    post1Date4:{
        EN:'19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'19/1(日)、25/1(六)、26/1(日)',
        簡:'19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time4:{
        EN:'4:00 pm - 5:00 pm (1 hour)',
        繁:'下午4:00 – 5:00 (1小時)',
        簡:'下午4:00 – 5:00 (1小时)',
    },
    post1Loca4:{
        EN:'Grassland outside LEE’s Mansion, Tin Sum',
        繁:'田心李氏大宅外草地',
        簡:'田心李氏大宅外草地',
    },
    post1Little:{
        EN:'Team Performance',
        繁:'小組合奏',
        簡:'小组合奏',
    },
    post1Big:{
        EN:'Group Performance',
        繁:'大合奏',
        簡:'大合奏',
    },
    // post2
    post2Title:{
        EN:'Gordon LEE',
        繁:'李俊樂',
        簡:'李俊乐',
    },
    post2Text:{
        EN:['Harmonica player Gordon Lee garnered international attention since he won the champion prize at the prestigious 8th World Harmonica Festival in Germany in 2017. In 2024, Lee was awarded the "Young Artist of the Year Award" by the Hong Kong Arts Development Council, and in the same year, he received the "Music Advocacy Award" from the International Society for Music Education in Finland.',
            'He was under the tutelage of two-time Grammy Award winner Howard Levy, Franz Chmel and Tak-wai Cheng.',
            `As a soloist, Lee has performed extensively with prestigious orchestras, namely Bursa Regional State Symphony Orchestra, Hong Kong Philharmonic Orchestra, the City Chamber Orchestra of Hong Kong, and the Hong Kong Chinese Orchestra. He was also invited to be a guest performer and adjudicator in numerous music festivals across Asia, America and Europe. In 2022, he held Hong Kong's first-ever solo harmonica concert at the Hong Kong City Hall Concert Hall, and was recognized as a "world-class soloist " by the IATC, a renowned international organization of critics.`,
            'In 2024, Lee was awarded the "Young Artist of the Year Award" by the Hong Kong Arts Development Council, and in the same year, he received the "Music Advocacy Award" from the International Society for Music Education in Finland.',
            'In addition, he has appeared as a recording artist and solo guest performer with Hong Kong artists such as Alan Tam, Hacken Lee, Eason Chan, Jacky Cheung, Hins Cheung, Leon Lai and Kay Tse.',
        ],
        繁:['李俊樂在2017年贏得德國世界口琴節冠軍。師從美國兩屆格林美大獎得主Howard Levy、奧地利口琴大師Franz Chmel、香港演藝學院碩士研究生導師鄭德惠。 ',
            '李氏曾應邀與多個知名樂團演出，包括土耳其布爾薩國家交響樂團、香港管弦樂團、香港城市室樂團、香港中樂團等，並被邀請於世界各地不同的音樂節演出，足跡遍布亞洲、美洲、歐洲。此外，他2022年於香港大會堂音樂廳舉辦香港首個口琴個人獨奏音樂會，被當代權威藝術評論組織「國際評論家協會」評為「世界級獨奏家」。',
            '2024年李氏獲香港藝術發展局頒發「藝術新秀獎」，同年於芬蘭獲國際音樂教育學會頒發「音樂倡導獎」。',
            '此外，李氏亦受邀與譚詠麟、張學友、陳奕迅、張敬軒、許冠傑、李克勤、黎明、謝安琪等歌手於紅館演唱會及電視演出。'],
        簡:['李俊乐在2017年赢得德国世界口琴节冠军。师从美国两届格林美大奖得主Howard Levy、奥地利口琴大师Franz Chmel、香港演艺学院硕士研究生导师郑德惠。',
            '李氏曾应邀与多个知名乐团演出，包括土耳其布尔萨国家交响乐团、香港管弦乐团、香港城市室乐团、香港中乐团等，并被邀请于世界各地不同的音乐节演出，足迹遍布亚洲、美洲、欧洲。此外，他2022年于香港大会堂音乐厅举办香港首个口琴个人独奏音乐会，被当代权威艺术评论组织「国际评论家协会」评为「世界级独奏家」。',
            '年李氏获香港艺术发展局颁发「艺术新秀奖」，同年于芬兰获国际音乐教育学会颁发「音乐倡导奖」。',
            '此外，李氏亦受邀与谭咏麟、张学友、陈奕迅、张敬轩、许冠杰、李克勤、黎明、谢安琪等歌手于红馆演唱会及电视演出。'],
    },
    // post3
    post3Title:{
        EN:'Vincent LIAUW ',
        繁:'廖原',
        簡:'廖原',
    },
    post3Text:{
        EN:['2019 Austrian Vienna Albert Schweitzer Association Gold Medal Winner Vincent LIAUW  was born in Beijing and grew up in Hong Kong.',
            'Since childhood he learnt violin, piano and trombone and played actively in Western orchestra and Chinese traditional orchestra before he received classical music education in Vienna.',
            'In Europe, Vincent learnt from Sergiu Celibedache, Václav Neumann and Professor Karl Österreicher and worked extensively with Bratislava Radio Orchestra, Slovakia State Orchester, Vienna Classic Orchester, The Philharmonics, Roby Lakatos and Janoska Ensemble.',
            'Vincent also engaged in Hong Kong pop music and has been working with a number of singers such as Alan TAM, Jacky CHEUNG, Jay CHOU, Hacken LEE, Aaron KWOK and Leon LAI, etc.',
            'Vincent also performed and worked as music director for Asia Film Award, Hong Kong Film Award, Miss Hong Kong as well as the MGM Music Festival in Macao. He was a TV host and presented more than 10 episodes of children music program. Currently, Vincent works frequently on music direction and composing for TV, Movie and Concerts.',
        ],
        繁:['在北京出生的廖原自五歲開始他的音樂之旅學習小提琴，及後到維也納主修音樂及指揮，期間廖氏加入維也納青年管弦樂團，成為少數的亞洲樂手。除了到歐洲各地演出，更認識了許多年青音樂家，從而促成日後跟不同著名樂團的合作。從歐洲回來後，廖氏除了參與香港的古典音樂工作，亦積極與本地年青音樂學生互動和教育，提升他們的音樂視野和興趣。',
            '另一方面，廖氏活躍於流行音樂的創作和演出，多年來與香港大部份歌手皆有合作，曾與譚詠麟及監製嚴勵行一同到歐洲為灌錄四十週年唱片《銀河歲月》，此行廖氏身負指揮六十多人的斯洛伐克國家交響樂團之重任。',
            '及後為電影《消失的愛人》再次與斯洛伐克國家交響樂團合作灌錄電影配樂，亦曾為電影《向左走、向右走》、《柔道龍虎榜》、《蝴蝶飛》、《龍鳳鬥》等作配樂工作。此外，在電視《香港小姐》的音樂總監、大型活動（第廿八至三十屆《香港電影金像獎》表演及音樂總監、第五十六屆《亞太電影展》表演嘉賓等）也都見到廖氏的參與，涉獵甚廣，正好是他希望推廣之「音樂大同」理念。',
        ],
        簡:['在北京出生的廖原自五岁开始他的音乐之旅学习小提琴，及后到维也纳主修音乐及指挥，期间廖氏加入维也纳青年管弦乐团，成为少数的亚洲乐手。除了到欧洲各地演出，更认识了许多年青音乐家，从而促成日后跟不同着名乐团的合作。从欧洲回来后，廖氏除了参与香港的古典音乐工作，亦积极与本地年青音乐学生互动和教育，提升他们的音乐视野和兴趣。',
            '另一方面，廖氏活跃于流行音乐的创作和演出，多年来与香港大部份歌手皆有合作，曾与谭咏麟及监製严励行一同到欧洲为灌录四十週年唱片《银河岁月》，此行廖氏身负指挥六十多人的斯洛伐克国家交响乐团之重任。',
            '及后为电影《消失的爱人》再次与斯洛伐克国家交响乐团合作灌录电影配乐，亦曾为电影《向左走、向右走》、《柔道龙虎榜》、《蝴蝶飞》、《龙凤斗》等作配乐工作。此外，在电视《香港小姐》的音乐总监、大型活动（第廿八至三十届《香港电影金像奖》表演及音乐总监、第五十六届《亚太电影展》表演嘉宾等）也都见到廖氏的参与，涉猎甚广，正好是他希望推广之「音乐大同」理念。',
        ],
    }
}