import {useRef, useEffect, useState } from 'react';
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom'

export function useLang() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const lang = searchParams.get('lang');

  useEffect(() => {
      if (!lang) {
        setLang()
      }
  }, [location.pathname]);
  const setLang = (newLang = '繁') => {
      searchParams.delete('lang')
      searchParams.set('lang', newLang);
      setSearchParams(searchParams);
  }

  return [(lang || '繁'), setLang];
}