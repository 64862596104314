import '../App.css';
import {useRef, useEffect, useState } from 'react';
import Navbar from '../conponents/Navbar'
import ShortDivider from '../conponents/ShortDivider'
import { s3_PREFIX } from '../config';

import { concertContent } from '../content/concert';
import { homeContent } from '../content/home';
import EventTable from '../conponents/EventTable'
import LogoSvg from '../svgs/LogoSvg';
import { useNavigate } from 'react-router-dom';
import Back from '../conponents/Backbtn'
function Component({lang, handleSetRightNav}) {
    const cc = concertContent
    const navigate = useNavigate();
    function navigating(str){ navigate(str) }
    return(<div className='relative flex flex-col w-full text-[black] h-full bg-[#D0D6D0]'>
        {/*header*/}
        {/* <div className='w-full bg-white flex flex-row justify-between items-center' style={{borderBottom:'18px solid #01A334', zIndex: 99}}>
            <Navbar lang={lang} top={true} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
        </div> */}
        <Navbar sticky={true} lang={lang} handleSetRightNav={(bol) => handleSetRightNav(bol)}/>
        {/*header*/}
        
        <div className='w-full flex justify-center items-center relative z-[1]'>
            <img src={s3_PREFIX+'/images/eventhighlights/deco2.png'} className='w-full' style={{height:undefined,aspectRatio:3840/726, objectFit:'contain'}} alt='bg'/>
        </div>
        <div className='flex flex-col w-full items-center pb-[48px] mt-[-15vw]' style={{zIndex:2}}>
            <Back lang={lang} path={`/event-highlights?lang=${lang}`}/>
            {/* <div className='flex row-col start-to-center justify-center w-full p-concert mt-[59px]' style={{gap: 117}}>
                <div className='flex flex-1 items-center justify-center w-full max-w-[545px]   '>
                    <div className='home-18 font-bold text-white bg-[#01A334] rounded-full w-full max-w-[545px] text-nowrap h-[69px] flex items-center justify-center'>{cc.titleL[lang]}</div>
                </div>
                <div className='flex flex-1 flex-col items-center'>
                    <div className='px-[136px] home-title border border-[4px] border-[#01A334] rounded-full w-full max-w-[484px] text-nowrap'>{cc.titleR[lang]}</div>
                    <ShortDivider color={'#01A334'} width={'100%'} maxW={380}/>
                </div>
            </div> */}
            <div className='flex row-col items-center justify-center w-full p-concert mt-[59px]' style={{gap: 117}}>
                <div className='home-25 font-bold text-white bg-[#01A334] rounded-full text-nowrap h-[69px] flex items-center justify-center px-[36px]'>
                    {cc.titleL[lang]}
                </div>  
            </div>
            {/* post start */}
            <div className='flex row-col justify-between w-full p-concert mt-[48px]' style={{gap: 117}}>
                <div className='flex flex-1 flex-col d-flex-m-hidden' style={{gap: 14}}>
                    <img className='w-[100%] rounded-xl' src={s3_PREFIX+'/images/home/sec4-1.jpg'} style={{height:undefined, aspectRatio:4/3, objectFit:'cover'}}></img>
                </div>
                <div className='flex flex-1 flex-col items-start'>
                    <div className='relative w-full flex-row flex'>
                        <div className='relative'>
                            <div className='text-[#008735] title-text-1 relative text-left pr-[16px]' style={{zIndex:999}}>{cc.post1Title[lang]}</div>
                            <div className='w-[100%] h-[16px] absolute bottom-0 bg-[#ffffff]' style={{zIndex:1}}></div>
                        </div>
                    </div>
                    <div className='text-justify mt-[31px] home-22-text'>{cc.post1Text[lang]}</div>
                    <div className='flex flex-1 flex-col d-hidden-m-flex mt-[24px]' style={{gap: 14}}>
                        <img className='w-[100%] rounded-xl' src={s3_PREFIX+'/images/home/sec4-1.jpg'} style={{height:undefined, aspectRatio:4/3, objectFit:'cover'}}></img>
                    </div> 
                    <div className='home-22 mt-[21px]'>{cc.post1Little[lang]}</div>
                    {/* <EventTable date={cc.post1Date1[lang]} time={cc.post1Time1[lang]} loca1={cc.post1Loca1[lang]} loca2={cc.post1Loca1_2[lang]}/>
                    <div className='h-[64px]'></div>              */}
                    <EventTable date={cc.post1Date2[lang]} time={cc.post1Time2[lang]} loca1={cc.post1Loca2[lang]} loca2={cc.post1Loca2_2[lang]}/>
                    <ShortDivider color={'#000000'} width={'100%'} maxW={'100%'} style={{marginTop: 48}}/>
                    <div className='home-22 mt-[53px]'>{cc.post1Big[lang]}</div>
                    <EventTable date={cc.post1Date3[lang]} time={cc.post1Time3[lang]} loca1={cc.post1Loca3[lang]}/>
                    <div className='h-[64px]'></div>             
                    <EventTable date={cc.post1Date4[lang]} time={cc.post1Time4[lang]} loca1={cc.post1Loca4[lang]}/>          
                </div>
            </div>
            {/* post end */}
            {/* post start */}
            <div className='flex row-col justify-between w-full p-concert mt-[100px]' style={{gap: 117}}>
                <div className='flex flex-1 flex-col d-flex-m-hidden' style={{gap: 14}}>
                    <img className='w-[100%] rounded-xl' src={s3_PREFIX+'/images/concert/post2.png'} style={{height:undefined, aspectRatio:4/3, objectFit:'cover'}}></img>
                </div>
                <div className='flex flex-1 flex-col items-start'>
                    <div className='relative w-full flex-row flex'>
                        <div className='relative'>
                            <div className='text-[#000] title-text-1 relative text-left pr-[16px]' style={{zIndex:999}}>{cc.post2Title[lang]}</div>
                            <div className='w-[100%] h-[16px] absolute bottom-0 bg-[#ffffff]' style={{zIndex:1}}></div>
                        </div>
                    </div>
                    <div className='text-justify mt-[31px] flex flex-col' style={{gap:24}}>
                        {cc.post2Text[lang]?.map((text) => <div id={text} className='text-justify home-22-text'>{text}</div>)}
                    </div>
                    <div className='flex flex-1 flex-col d-hidden-m-flex mt-[24px]' style={{gap: 14}}>
                        <img className='w-[100%] rounded-xl' src={s3_PREFIX+'/images/concert/post2.png'} style={{height:undefined, aspectRatio:4/3, objectFit:'cover'}}></img>
                    </div>
                </div>
            </div>
            {/* post end */}
            <div className='d-flex-m-hidden w-full flex-row justify-center'>
                <ShortDivider color={'#000000'} width={'100%'} maxW={'40%'} style={{marginTop: 48}}/>
            </div>
            <div className='d-hidden-m-flex w-full flex-row justify-center p-concert'>
                <ShortDivider color={'#000000'} width={'100%'} maxW={'100%'} style={{marginTop: 48}}/>
            </div>
            {/* post start */}
            <div className='flex row-col justify-between w-full p-concert mt-[100px]' style={{gap: 117}}>
                <div className='flex flex-1 flex-col d-flex-m-hidden' style={{gap: 14}}>
                    <img className='w-[100%] rounded-xl' src={s3_PREFIX+'/images/concert/post3.png'} style={{height:undefined, aspectRatio:4/3, objectFit:'cover'}}></img>
                </div>
                <div className='flex flex-1 flex-col items-start'>
                    <div className='relative w-full flex-row flex'>
                        <div className='relative'>
                            <div className='text-[#000] title-text-1 relative text-left pr-[16px]' style={{zIndex:999}}>{cc.post3Title[lang]}</div>
                            <div className='w-[100%] h-[16px] absolute bottom-0 bg-[#ffffff]' style={{zIndex:1}}></div>
                        </div>
                    </div>
                    <div className='text-justify mt-[31px] flex flex-col' style={{gap:24}}>
                        {cc.post3Text[lang]?.map((text) => <div id={text} className='text-justify home-22-text'>{text}</div>)}
                    </div>
                    <div className='flex flex-1 flex-col d-hidden-m-flex mt-[24px]' style={{gap: 14}}>
                        <img className='w-[100%] rounded-xl' src={s3_PREFIX+'/images/concert/post3.png'} style={{height:undefined, aspectRatio:4/3, objectFit:'cover'}}></img>
                    </div>
                </div>
            </div>
            {/* post end */}

        </div>

        <div className='w-full flex justify-center items-center relative'>
                <img src={s3_PREFIX+'/images/eventhighlights/deco3.png'} className='w-full' style={{height:undefined,aspectRatio:3840/1568, objectFit:'contain'}} alt='bg'/>
            </div>
    </div>)
}

export default Component