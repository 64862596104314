import './nav.css'
import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { homeContent } from '../content/home';
import { s3_PREFIX } from '../config';

//svg

function Component(props) {
  const navigate = useNavigate();
  const location = useLocation()
  const [curScreen, setCurScreen] = useState('/')
  useEffect(() => {
    const pathName = location.pathname.split('/')[1]
    setCurScreen(`/${pathName}`)
  },[location])
  //scroll appear nav
  function navigating(str){
    // setCurScreen(str)
    navigate(str)
  }

  const [isMenuExpanded,setisMenuExpanded]=useState(false)
  function toogleMenu(){setisMenuExpanded(!isMenuExpanded)}

  const [windowSize, setWindowSize] = useState(getWindowSize());
    function getWindowSize() {const {innerWidth, innerHeight} = window;return {innerWidth, innerHeight};}
    useEffect(()=>{
        function handleWindowResize() {setWindowSize(getWindowSize()); }
        window.addEventListener('resize', handleWindowResize);
        return () => {window.removeEventListener('resize', handleWindowResize);};
  },[])

  const nav = (
  <div className='select-none w-full h-full flex flex-row items-center justify-between pt-[14px] pb-[2px] bg-transparent d-p41-m-p14 d-100h-m-70h' style={{zIndex: 999999}}>
      <div className='cursor-pointer flex items-center gap-4 cursor-pointer h-full py-[4px]' onClick={(() => navigating(`/?lang=${props.lang}`))}>
        <img src={s3_PREFIX+'/logo-w.png'} className='nav-img-height' style={{aspectRatio: 149/96, width:undefined, objectFit:'contain'}}/>
      </div>
      <div className='flex flex-row items-center h-full'>
        <div className='flex row-col-nav flex-wrap justify-end wgap-14-m-5'>
          <div className='flex flex-row items-center'>
            <a href='#ticket' onClick={() => navigating(`/?lang=${props.lang}#ticket`)} className='footer-18 text-bold py-[4px] px-[8px] text-white bg-[#e87db6] rounded-full font-[500] cursor-pointer wgap-14-m-5 text-nowrap'>{homeContent.topNavTicket[props.lang]}</a>
            <a href='#info' onClick={() => navigating(`/?lang=${props.lang}#info`)} className='footer-18 text-bold py-[4px] px-[8px] text-white bg-[#8e88e3] rounded-full font-[500] cursor-pointer text-nowrap'>{homeContent.topNavTimetable[props.lang]}</a>
          </div>
          <a onClick={() => navigating(`/event-highlights?lang=${props.lang}`)} className='footer-18 text-bold py-[4px] px-[8px] text-white bg-[#01A334] rounded-full font-[500] cursor-pointer'>{homeContent.topNavEvent[props.lang]}</a>
          {/* <a href='#info' onClick={() => navigating(`/?lang=${props.lang}#info`)} className='footer-18 text-bold py-[6px] px-[12px] text-white bg-[#63c2a4] rounded-full font-[600] cursor-pointer'>{homeContent.topNavMap[props.lang]}</a> */}
        </div>
        <div onClick={() => props.handleSetRightNav(true)} className='cursor-pointer h-full rounded-full bg-[#01A334] flex flex-col items-center justify-center' style={{aspectRatio: 1, height:undefined}}>
          <div className='flex flex-col mt-[20px]' style={{gap:4}}>
            <div className='h-[2px] w-[25px] bg-white rounded-full'></div>
            <div className='h-[2px] w-[25px] bg-white rounded-full'></div>
          </div>
          <div className='text-[12px] text-white font-[500] mt-[6px]'>
            {homeContent.navOpenBtn[props.lang]}
          </div>
        </div>
      </div>
  </div>)
  if(props.sticky){
    return (<div className={'sticky w-[full] top-[0px] left-0 right-0 text-white flex flex-col items-center justify-center d-px100-m-px24 bg-white border-b-[8px] border-b-[#01A334]'} style={{zIndex:99}}>
      {nav}
    </div>)
  }
  if(props.top){
    return nav
  }
  return (
    <div className={'absolute w-[full] top-[0px] left-0 right-0 text-white flex flex-col items-center justify-center slide-in-top d-px100-m-px24 bg-white border-b-[8px] border-b-[#01A334] ' + (props.show ? 'slide-in-top' : 'slide-out-top')} style={{zIndex:99}}>
          {nav}
    </div>
  );
}

export default Component
