import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={49}
    height={49}
    fill="none"
    viewBox="0 0 49 49"
    id={props.id}
    {...props}
  >
    <path
      fill="#41A044"
      d="M24.1 5.11c-10.47 0-18.99 8.52-18.99 18.99 0 10.47 8.52 18.99 18.99 18.99.76 0 1.51-.04 2.25-.14 8.26-.97 14.91-7.27 16.42-15.35.21-1.13.32-2.3.32-3.5 0-4.54-1.6-8.71-4.27-11.98-3.48-4.28-8.79-7.01-14.72-7.01Zm.86 1.39v2.45a.794.794 0 1 1-1.59 0V6.49c.24 0 .48-.01.73-.01.29 0 .57 0 .86.02ZM6.48 24.1v-.6h2.33c.44 0 .79.36.79.8 0 .44-.35.79-.79.79H6.5c-.02-.33-.03-.66-.03-.99h.01Zm16.89 17.61v-2.06c0-.44.35-.79.79-.79.44 0 .8.35.8.79v2.05c-.29.02-.57.02-.86.02-.24 0-.48 0-.73-.01Zm2.95-.12v-1.93c0-1.19-.97-2.16-2.16-2.16-1.19 0-2.16.96-2.16 2.16v1.95c-7.96-.95-14.3-7.22-15.37-15.14h2.18c1.19 0 2.16-.97 2.16-2.16 0-1.19-.96-2.16-2.16-2.16H6.58C7.48 14.04 13.91 7.57 22 6.61v2.35c0 1.19.97 2.16 2.16 2.16 1.19 0 2.16-.97 2.16-2.16V6.63c8.03 1.02 14.39 7.46 15.29 15.52h-2.1c-1.19 0-2.16.97-2.16 2.16 0 .67.31 1.27.79 1.66.37.31.85.49 1.38.49h2.06c-1.06 7.88-7.34 14.13-15.25 15.12l-.01.01Zm15.4-17.49c0 .33 0 .66-.03.99h-2.18a.794.794 0 1 1 0-1.59h2.2v.6h.01Z"
    />
    <path
      fill="#fff"
      d="M38.14 25.97c-.48-.4-.79-.99-.79-1.66 0-1.19.97-2.16 2.16-2.16h2.1c-.9-8.06-7.25-14.5-15.29-15.52v2.33c0 1.19-.97 2.16-2.16 2.16-1.19 0-2.16-.97-2.16-2.16V6.61c-8.09.96-14.51 7.43-15.42 15.54h2.23c1.19 0 2.16.97 2.16 2.16 0 1.19-.96 2.16-2.16 2.16H6.63C7.7 34.39 14.04 40.66 22 41.61v-1.95c0-1.19.97-2.16 2.16-2.16 1.19 0 2.16.96 2.16 2.16v1.93c7.9-1 14.18-7.24 15.25-15.12h-2.06c-.52 0-1-.18-1.38-.49l.01-.01Zm-.78 4.87c-.35.81-1.15 1.3-1.98 1.3-.29 0-.58-.06-.86-.17L23.3 27.1a2.121 2.121 0 0 1-1.29-1.94v-9.48c0-1.19.97-2.16 2.16-2.16 1.19 0 2.16.97 2.16 2.16v8.03l9.92 4.3c.53.23.94.65 1.15 1.19.21.54.2 1.12-.03 1.65l-.01-.01Z"
    />
    <path
      fill="#41A044"
      d="M24.1 0h-.36C10.62.19 0 10.93 0 24.1c0 13.17 10.81 24.1 24.1 24.1 4.07 0 7.91-1.01 11.28-2.8C43 41.35 48.21 33.32 48.21 24.1 48.2 10.81 37.39 0 24.1 0Zm13.93 42.06c-3.85 3-8.68 4.78-13.93 4.78-12.53 0-22.73-10.2-22.73-22.73 0-12.53 10.19-22.74 22.73-22.74 1.2 0 2.38.09 3.53.27 10.87 1.7 19.21 11.13 19.21 22.47 0 7.29-3.45 13.79-8.81 17.95Z"
    />
    <path
      fill="#41A044"
      d="m36.24 28-9.92-4.3v-8.03c0-1.19-.97-2.16-2.16-2.16-1.19 0-2.16.97-2.16 2.16v9.48c.02.85.52 1.61 1.3 1.94l11.22 4.87c.28.12.57.17.86.17.83 0 1.63-.48 1.98-1.3.23-.53.24-1.12.03-1.65-.21-.54-.62-.96-1.15-1.19V28Zm-.13 2.3a.79.79 0 0 1-.44.42.78.78 0 0 1-.6 0l-11.23-4.87c-.3-.13-.48-.42-.47-.78v-9.4a.794.794 0 1 1 1.59 0v8.93l10.75 4.66c.19.08.34.24.42.44.08.2.08.41 0 .61l-.02-.01Z"
    />
  </svg>
)
export default SvgComponent
