export const footerContent = {
     close: {
        EN:'close',
        繁:'關閉',
        簡:'关闭',
    },
    foot1: {
        EN:'Home',
        繁:'主頁',
        簡:'主页',
    },
    foot2: {
        EN:'Event Highlights',
        繁:'亮點節目',
        簡:'亮点节目',
    },
    foot3: {
        EN:'Village Experience',
        繁:'鄉郊體驗',
        簡:'乡郊体验',
    },
    foot4: {
        EN:'About Kuk Po',
        繁:'有關谷埔',
        簡:'有关谷埔',
    },
    foot5: {
        EN:'About Us',
        繁:'關於我們',
        簡:'关于我们',
    },
    footR1:{
        EN:'Organiser',
        繁:'主辦單位',
        簡:'主办单位',
    },
    footR2:{
        EN:'Curatorial Team',
        繁:'策展團隊',
        簡:'策展团队',
    }
}