function App({width, color, px, py, maxW, style}) {

    return (
    <div style={{width:width, padding: `${(py||0)}px ${(px||0)}px`, maxWidth: maxW, ...style}} className="h-[12px] flex items-center justify-center my-[6px] relative">
        <div style={{width:width, backgroundColor:(color || 'white')}} className="h-[1px] absolute top-[46%] left-0 rounded-full" >
        </div>
        <div style={{backgroundColor:(color || 'white'), opacity: 0.8}} className="w-[12px] h-[12px] rounded-full "></div>
    </div>
    );
  }
  
  export default App;