export const artKukPoContent = {
    titleL:{
        EN:'Sight @ Kuk Po',
        繁:'色@谷埔',
        簡:'色@谷埔',
    },
    titleR:{
        EN:'Exhibition',
        繁:'藝術展覽',
        簡:'艺术展览',
    },
    post1Title:{
        EN:'Kai Choi School Exhibition “Art Kuk Po” ',
        繁:'啟才學校藝術展覽',
        簡:'启才学校艺术展览',
    },
    post1Text:{
        EN:['The Art Kuk Po Exhibition, led by Centre for Chinese Architecture and Urbanism, Faculty of Architecture, The University of Hong Kong, will showcase how artist Koon Wai Bong and his team use their representative styles to present the Kuk Po values and culture.',
            'Koon Wai Bong is the leading artist and curator of this exhibition, the team members also includes artist Angel Hui Hoi Kiu, postgraduate and undergraduate students from different universities.',
            'This exhibition is funded by Countryside Conservation Funding Scheme.'
        ],
        繁:['藝術谷埔系列展覽由香港大學中國建築與城市研究中心主辦。展覽將展示藝術家管偉邦及團隊如何通過不同的藝術手段，表現谷埔的文化與價值。','管偉邦是本次展覽的領軍藝術家和策展人，團隊成員還包括藝術家許開嬌及各大學的研究生和本科生等。','本展覽由鄉郊保育計劃資助。'],
        簡:['艺术谷埔系列展览由香港大学中国建筑与城市研究中心主办。展览将展示艺术家管伟邦及团队如何通过不同的艺术手段，表现谷埔的文化与价值。','管伟邦是本次展览的领军艺术家和策展人，团队成员还包括艺术家许开娇及各大学的研究生和本科生等。','本展览由乡郊保育计划资助。'],
    },
    post1Date1:{
        EN:'18/1(Sat), 19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
        簡:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time1:{
        EN:'10:30 am – 5:00 pm',
        繁:'上午10:30 – 下午5:00',
        簡:'上午10:30 – 下午5:00',
    },
    post1Loca1:{
        EN:'Kuk Po Common @ Kai Choi School',
        繁:'谷埔客廳@啟才學校',
        簡:'谷埔客厅@启才学校',
    },
}