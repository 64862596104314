import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={53}
    viewBox="0 0 50 53"
    fill="none"
    id={props.id}
    {...props}
  >
    <path
      fill="#41A044"
      d="M29.681 39.42h-5.75c3.84-7.83 9.75-20.69 9.75-25.65 0-.32 0-.64-.04-.96-.48-7-6.34-12.54-13.46-12.54-7.12 0-12.51 5.12-13.38 11.73-.08.58-.12 1.17-.12 1.77 0 4.96 5.92 17.82 9.75 25.65h-5.85L.201 50.93c-.21.23-.26.56-.13.85a.797.797 0 0 0 .72.47h38.71a.786.786 0 0 0 .58-1.32l-10.4-11.51ZM7.951 13.77c0-.83.08-1.64.24-2.43 1.13-5.59 6.08-9.81 12-9.81s11.29 4.59 12.12 10.54c.08.56.12 1.12.12 1.7 0 4.78-6.13 17.98-9.9 25.65-.22.44-.43.86-.62 1.26-.72 1.45-1.32 2.62-1.72 3.4-.4-.79-1-1.96-1.72-3.4l-.63-1.26c-3.77-7.67-9.9-20.87-9.9-25.65h.01Zm12.24 37.21H1.851l9.31-10.31h5.91c1.41 2.84 2.42 4.8 2.57 5.07l.56 1.06.56-1.06c.15-.27 1.16-2.23 2.57-5.07h5.81l9.32 10.31H20.191Z"
    />
    <path
      fill="#41A044"
      d="M25.131 19.15c2.59-2.59 2.59-6.82 0-9.42-.09-.09-.17-.17-.26-.25a6.683 6.683 0 0 0-9.15.25 6.638 6.638 0 0 0-1.94 4.71c0 1.7.64 3.41 1.94 4.71a6.64 6.64 0 0 0 4.7 1.94c1.7 0 3.41-.65 4.71-1.94Zm-8.52-8.53c2.1-2.11 5.53-2.1 7.64 0 2.1 2.1 2.1 5.53 0 7.63a5.41 5.41 0 0 1-7.64 0c-2.1-2.1-2.1-5.53 0-7.63Z"
    />
    <path
      fill="#fff"
      d="M20.191 44.08c.4-.79 1-1.96 1.72-3.4.19-.4.4-.82.62-1.26 3.77-7.67 9.9-20.87 9.9-25.65 0-.58-.04-1.14-.12-1.7-.83-5.95-5.95-10.54-12.12-10.54-6.17 0-10.87 4.22-12 9.81-.16.79-.24 1.6-.24 2.43 0 4.78 6.12 17.98 9.9 25.65l.63 1.26c.72 1.45 1.31 2.62 1.72 3.4h-.01Zm-4.47-24.93c-1.3-1.29-1.94-3-1.94-4.71a6.621 6.621 0 0 1 2.63-5.31 6.684 6.684 0 0 1 8.46.35 6.681 6.681 0 0 1 .26 9.67c-1.3 1.29-3 1.94-4.71 1.94s-3.41-.65-4.7-1.94Z"
    />
  </svg>
)
export default SvgComponent
