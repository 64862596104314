export const ex8 = {
    titleL:{
        EN:'Sight @ Kuk Po',
        繁:'色@谷埔',
        簡:'色@谷埔',
    },
    titleR:{
        EN:'Restoration experiment',
        繁:'微建築修復',
        簡:'微建筑修復',
    },
    post1Title:{
        EN:'Lo Wai Community Garden – Demonstration of Micro-renewal Restoration Experiment',
        繁:'老圍社區花園——微建築修復實驗展示',
        簡:'老围社区花园——微建筑修復实验展示',
    },
    post1Text:{
        EN:['Funded by CCFS, the Centre for Chinese Architecture and Urbanism, Faculty of Architecture, The University of Hong Kong utilise an collapsed village house as a experiment site, to create a public garden through micro-renewal approach in Lo Wai. The design demonstrates principles of authenticity in conservation, providing public space for visitors and showcasing the symbiotic relationships between architecture and landscape for traditional Hakka settlements in Hong Kong.',],
        繁:['在鄉郊保育計劃資助下，香港大學中國建築與城市研究中心以谷埔老圍其中一棟荒廢村屋為試點，用微更新方式建立公眾花園。','社區花園的設計示範了保育中的本真性原則，除了提供公共空間外，亦展示客家建築與自然的共生關係。'],
        簡:['在乡郊保育计划资助下，香港大学中国建筑与城市研究中心以谷埔老围其中一栋荒废村屋为试点，用微更新方式建立公众花园。','社区花园的设计示范了保育中的本真性原则，除了提供公共空间外，亦展示客家建筑与自然的共生关係。'],
    },
    post1Date1:{
        EN:'18/1(Sat), 19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
        簡:'18/1(六)、19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time1:{
        EN:'12:00 noon – 5:00 pm',
        繁:'中午12:00 – 下午5:00',
        簡:'中午12:00 – 下午5:00',
    },
    post1Loca1:{
        EN:'Community Garden, Lo Wai',
        繁:'老圍社區花園',
        簡:'老围社区花园',
    },
}