export const ArtInstallationsContent = {
    titleL:{
        EN:'Sight @ Kuk Po',
        繁:'色@谷埔',
        簡:'色@谷埔',
    },
    titleR:{
        EN:'Art Installation',
        繁:'藝術裝置展覽',
        簡:'艺术装置展览',
    },
    post1Title:{
        EN:'Kuk Po Art Exhibition – Find the Lights',
        繁:'谷埔藝術展覽––看見',
        簡:'谷埔艺术展览––看见',
    },
    post1Content:{
        EN:"The School of Design at The Hong Kong Polytechnic University, in collaboration with local and international artists, has created more than 20 art installations along a 1.4-kilometer pathway connecting Kai Choi School, San Wai, Tin Sum, Hoi Ha and the dam in Kuk Po. By day, the installations evoke the charm of the village's past, while at night, illuminated pathways guide visitors, offering them an immersive journey through the stories and culture of Kuk Po.",
        繁:'香港理工大學設計學院聯同本地及海外藝術家設計超過20組藝術裝置，圍繞谷埔的啟才學校、新圍、田心、海下及堤壩的1.4公里行人路，日間展示昔日鄉村的氣氛，晚間則以燈光引路，與遊人分享谷埔的故事及文化。',
        簡:'香港理工大学设计学院联同本地及海外艺术家设计超过20组艺术装置，围绕谷埔的启才学校、新围、田心、海下及堤坝的1.4公里行人路，日间展示昔日乡村的气氛，晚间则以灯光引路，与游人分享谷埔的故事及文化。',
    },
    post1Tag1:{
        EN:'PolyU Kuk Po Team',
        繁:'理大重聚谷埔團隊',
        簡:'理大重聚谷埔团队',
    },
    post1Tag2:{
        EN:'Kagn Ho',
        繁:'何裕勤',
        簡:'何裕勤',
    },
    post1Tag3:{
        EN:'Canalside Studio',
        繁:'Canalside Studio',
        簡:'Canalside Studio',
    },
    post1Tag4:{
        EN:'Seika Studio',
        繁:'青花工作室',
        簡:'青花工作室',
    },
    post1Tag5:{
        EN:'YT',
        繁:'YT聿廷',
        簡:'YT聿廷',
    },
    post1Tag6:{
        EN:'Toby Crispy',
        繁:'林蔚彦',
        簡:'林蔚彦',
    },
    post1Tag7:{
        EN:'Hang Li',
        繁:'李萬鏗',
        簡:'李万铿',
    },
    post1Tag8:{
        EN:'Wan Kin On',
        繁:'温健安',
        簡:'温健安',
    },
    post1Tag9:{
        EN:'Ada Chan',
        繁:'陳盈敏',
        簡:'陈盈敏',
    },
    post1Time:{
        EN:'18/1(Sat) - 16/2 (Sun)',
        繁:'18/1(六)至16/2(日)',
        簡:'18/1(六)至16/2(日)',
    },
    post1Date:{
        EN:'12:00 noon – 7:00 pm',
        繁:'中午12:00 – 下午7:00',
        簡:'中午12:00 – 下午7:00',
    },
    post1Location:{
        EN:'Pathway along Kai Choi School, San Wai, Tin Sum, Hoi Ha and the dam',
        繁:'啟才學校、新圍、田心、海下、堤壩等',
        簡:'启才学校、新围、田心、海下、堤坝等',
    },
    post1Sp1Title:{
        EN:'Light-up Arrangement',
        繁:'亮燈安排',
        簡:'亮灯安排',
    },
    post1Sp1Date:{
        EN:'18/1, 19/1, 25/1, 26/1',
        繁:'18/1、19/1、25/1、26/1',
        簡:'18/1、19/1、25/1、26/1',
    },
    post1Sp1Time:{
        EN:'5:00 pm - 7:00 pm',
        繁:'下午5:00-7:00',
        簡:'下午5:00-7:00',
    },

    post2Tag:{
        EN:'Introduction',
        繁:'關於創作人',
        簡:'关于创作人',
    },
    post2Title:{
        EN:'PolyU Kuk Po Team',
        繁:'理大重聚谷埔團隊',
        簡:'理大重聚谷埔团队',
    },
    post2Subtitle:{
        EN:'Artwork: The People of Kuk Po',
        繁:'作品：《谷埔之民》故事路牌系列',
        簡:'作品：《谷埔之民》故事路牌系列',
    },
    post2Content:{
        EN:'The PolyU team came to Kuk Po Village in November 2021 to put the concepts of community building into practice. From knowing only two village heads to becoming part of the village, they collected oral histories from over 30 villagers and gathered more than a hundred photos taken in the 50s to 90s from them, local or living overseas. Using texts and images, they also published Kuk Post!, the community paper, and curated two exhibitions and events to keep the hundred-year-old history alive and tell the stories of Kuk Po to Hong Kong and the world. Team members include Michael Chan, Sam Fu, Dara Yip, Mahpee Ng, Yuen Chi Yan, Ada Chan, Kagn Ho and Navin Wong.',
        繁:'理工大學團隊於2021年11月走入谷埔村和本地鄉村，實踐社區營造的理念。從只認識兩位村長，變成村落一份子，跟40多位村民進行口述歷史，從本地和海外村民手中，收集過百張由1950年至90年代的舊照片，並出版《谷報》地區報，舉辦兩次的展覽和一次大型的節慶，用插畫和文字，還原村落百年的歷史，將谷埔故事帶給香港和世界。團隊成員包括陳翔教授、符士汶、葉穎珊、袁智仁、黃卓軒、何裕勤、陳盈敏及吳文斌。',
        簡:'理工大学团队于2021年11月走入谷埔村和本地乡村，实践社区营造的理念。从只认识两位村长，变成村落一份子，跟40多位村民进行口述历史，从本地和海外村民手中，收集过百张由1950年至90年代的旧照片，并出版《谷报》地区报，举办两次的展览和一次大型的节庆，用插画和文字，还原村落百年的历史，将谷埔故事带给香港和世界。团队成员包括陈翔教授、符士汶、叶颖珊、袁智仁、黄卓轩、何裕勤、陈盈敏及吴文斌。',
    },


    post3Title:{
        EN:'Kagn Ho',
        繁:'何裕勤',
        簡:'何裕勤',
    },
    post3Subtitle:{
        EN:'Artwork: Glow of Harvest',
        繁:'作品：堤映步稻',
        簡:'作品：堤映步稻',
    },
    post3Content:{
        EN:`Kagn is a landscape architect, his artwork "Glow of Harvest" was inspired by the rural life in Kuk Po. He collaborated with over 50 citizens and villagers, using handcrafting techniques to recreate the splendour of the rice harvest season. Skilled at integrating nature and art, Kagn utilizes natural materials and traditional craftsmanship to showcase diverse creative works. He has previously served as a mentor for PolyU students at the Tai O Cultural Festival.\r\n\r\nKagn's works emphasize sustainability and local knowledge - art is not merely about aesthetics, but also a means of participation. It helps establish a sense of identity for the village and brings long-term transformation to the community, delivering benefits.`,
        繁:'何裕勤任職園境建築，他的《堤映步稻》創作，從谷埔鄉村的生活作靈感，與超過50位市民、村民合作，利用人手紥作方式，重現鄉郊稻米豐收的盛況。他擅長融入自然與藝術，利用天然材料，傳統手藝，展示多元的創作，並曾於大澳文化節擔任指導導師。他的作品強調可持續性與在地知識，藝術不單是美觀，也是參與的方式，為村落建立認同，長遠改造社區，帶來裨益。',
        簡:'何裕勤任职园境建筑，他的《堤映步稻》创作，从谷埔乡村的生活作灵感，与超过50位市民、村民合作，利用人手紥作方式，重现乡郊稻米丰收的盛况。他擅长融入自然与艺术，利用天然材料，传统手艺，展示多元的创作，并曾于大澳文化节担任指导导师。他的作品强调可持续性与在地知识，艺术不单是美观，也是参与的方式，为村落建立认同，长远改造社区，带来裨益。',
    },


    post4Title:{
        EN:'Canalside Studio',
        繁:'Canalside Studio',
        簡:'Canalside Studio',
    },
    post4Subtitle:{
        EN:'Artwork: Mini Water Intervention',
        繁:'作品：微型水裝置',
        簡:'作品：微型水装置',
    },
    post4Content:{
        EN:`Canalside Studio is a creative design company headquartered in Hong Kong, covering architecture, interior design and placemaking. Although a young company, Canalside has a diverse portfolio including hotels, wellness and an ongoing art museum renovation project. Founders Etain Ho and Francis Lam previously worked at Heatherwick Studio in London, and they lead Canalside's collaborative process. They firmly believe that each client and each project is unique, with its own compelling story - deeply rooted in history, place and culture.`,
        繁:'Canalside Studio是一家總部位於香港的創意設計公司，涵蓋建築、室內設計和場所創造。儘管是一家年輕的公司，Canalside擁有多樣化的作品集，包括酒店、健康與保健以及正在進行中的藝術博物館翻新項目。創始人Etain Ho和Francis Lam曾在倫敦的Heatherwick Studio工作，他們領導著Canalside，並堅信每個客戶和每個項目都是獨一無二的，擁有其自身引人入勝的故事，這些故事深深植根於歷史、地點和文化之中。',
        簡:'Canalside Studio是一家总部位于香港的创意设计公司，涵盖建筑、室内设计和场所创造。儘管是一家年轻的公司，Canalside拥有多样化的作品集，包括酒店、健康与保健以及正在进行中的艺术博物馆翻新项目。创始人Etain Ho和Francis Lam曾在伦敦的Heatherwick Studio工作，他们领导着Canalside，并坚信每个客户和每个项目都是独一无二的，拥有其自身引人入胜的故事，这些故事深深植根于历史、地点和文化之中。',
    },



    post5Title:{
        EN:'Seika Studio',
        繁:'青花工作室',
        簡:'青花工作室',
    },
    post5Subtitle:{
        EN:'Artwork: (1) Kandelia Troupe, (2) Be Quick!',
        繁:'作品：(1)⽔筆仔劇團、(2)喳喳淋',
        簡:'作品：(1)⽔笔仔剧团、(2)喳喳淋',
    },
    post5Content:{
        EN:'The creative inspiration of Seika Studio comes from everything in nature, including flowers, trees, birds, fish and insects. They hope that their works can bring the beautiful side of nature to everyone and add a sense of leisurely greenery to busy lives. In addition, they like to integrate Chinese and Western traditional culture or artistic styles into paintings, so as to preserve traditional culture and create new elements at the same time.\r\n\r\nSeika Studio has held many exhibitions in Hong Kong and has also curated exhibitions for local and overseas artists. They have also collaborated with a number of conservation and environmental protection organizations to raise public awareness of environmental protection and concern for nature through artistic means.',
        繁:'青花工作室的創作靈感源自大自然的一切，包括花草樹木、飛鳥魚蟲。他們希望自己的作品能夠將大自然美好的一面帶給大家，為繁忙勞碌的生活增添一份悠然綠意。此外，他們喜歡將中西方傳統文化或藝術風格融入畫作之中，在保育傳統文化的同時亦能創造新的元素。\r\n\r\n青花工作室多次在香港舉辦展覽，也有為本地及海外藝術家策展。他們亦曾與多家保育及環保機構合作，透過藝術方式提高大眾的環保意識，以及對大自然的關注。',
        簡:'青花工作室的创作灵感源自大自然的一切，包括花草树木、飞鸟鱼虫。他们希望自己的作品能够将大自然美好的一面带给大家，为繁忙劳碌的生活增添一份悠然绿意。此外，他们喜欢将中西方传统文化或艺术风格融入画作之中，在保育传统文化的同时亦能创造新的元素。\r\n\r\n青花工作室多次在香港举办展览，也有为本地及海外艺术家策展。他们亦曾与多家保育及环保机构合作，透过艺术方式提高大众的环保意识，以及对大自然的关注。',
    },


    post6Title:{
        EN:'YT',
        繁:'YT聿廷',
        簡:'YT聿廷',
    },
    post6Subtitle:{
        EN:'Artwork: Flow with Nature',
        繁:'作品：與自然流動',
        簡:'作品：与自然流动',
    },
    post6Content:{
        EN:'Since 2014, YT has started the journey of Waldorf early childhood education program and various types of anthroposophical art courses. That same year, YT began conducting workshops with different organizations and elementary schools, focusing on movement and art education for holistic development. In 2019, he completed a three-year, 720-hour Bothmer®️ Movement course, certified by Bothmer®️ Movement International in the UK. Bothmer program is based on the philosophy of Anthroposophy which was founded by the Austrian educator, Rudolf Steiner, aiming to support children and young people in achieving balanced development in body, mind, and spirit.',
        繁:'YT聿廷從2014年開始就讀華德福幼師課程及不同類型的人智學藝術課程，同年開始與不同機構及小學舉辦工作坊，開展以身心靈發展的運動及藝術教育。2019年完成了三年制共720小時由英國Bothmer®️ Movement International 頒發的 Bothmer®️ Movement 空間運動課程 ，理念源於由奧地利哲學及教育家 Rudolf Steiner 的人智學理念為主，從而幫助兒童及年青人在身心靈三方面平衡發展。',
        簡:'YT聿廷从2014年开始就读华德福幼师课程及不同类型的人智学艺术课程，同年开始与不同机构及小学举办工作坊，开展以身心灵发展的运动及艺术教育。2019年完成了三年制共720小时由英国Bothmer®️ Movement International 颁发的 Bothmer®️ Movement 空间运动课程 ，理念源于由奥地利哲学及教育家 Rudolf Steiner 的人智学理念为主，从而帮助儿童及年青人在身心灵三方面平衡发展。',
    },


    post7Title:{
        EN:'Toby Crispy',
        繁:'林蔚彦',
        簡:'林蔚彦',
    },
    post7Subtitle:{
        EN:'Artwork: SLOW STiTCH NOMAD @ Kuk Po "MiSS YOU iN THE AiR"',
        繁:'作品：慢針黹牧民@谷埔《思念在半空》',
        簡:'作品：慢针黹牧民@谷埔《思念在半空》',
    },
    post7Content:{
        EN:'The founder of Fashion Clinic by T has worked in different sectors in the fashion field ranging from magazine editor to fashion designer for international brands. She worked as a design manager at French label agnès b. before starting her upcycling fashion journey in 2013. Since then, Toby has been focusing on redesigning fashion service & upcycling textile art, running exhibitions, workshops and events with various fashion brands, corporate, shopping malls and art groups, e.g. The Mills, Oi!, New Art Power...., in order to propose benefits to clothing creation beyond fashion, and simultaneously stitch up a sustainable future. \r\n\r\nBringing Fashion Clinic to life in 2016, she added "by T" in 2020 to highlight the Time and Tales in Textile which are worth it to pass down from generation to generation. Wearing another hat as an independent curator, Toby blends into her sustainable design practice the space for personal meditation and social healing. Through her SLOW STiTCH NOMAD art projects, she reconnects the relationship between people and clothing, and demonstrates how design responds to the meaning of sustainable living. \r\n\r\nShe truly believes that redesigning is the best way to make the best use of resources. In this way, it cuts down over-consumption and waste of resources at its root. ',
        繁:'林蔚彦是FashionClinic by T的創辦人，涉獵時裝工業裡多個範疇，由時裝雜誌編輯到國際品牌設計師，曾擔任法國品牌agnès b.設計經理。Toby Crispy覺醒快速時尚文化需要「治療」，自2013年起，透過與不同的國際品牌、企業、環保及藝術單位合作，推出再生時裝設計、舉辦工作坊、藝術展覽、織物裝置等，為衣物創作帶來跨越時尚之外的好處，延長織物的壽命和傳承手藝。\r\n\r\n2016年她發起FashionClinic，並在2020年添加了「byT」 強調衣物中的時間(Time)和故事(Tale)的意義；以日常衣物代替時鐘，記錄時間和故事。同時作為獨立策展人，Toby通過她的慢針黹牧民(SLOW STiTCH NOMAD)藝術項目將個人和社區療癒的空間融入到永續設計的實踐中，以一針一線的「慢 針黹」藝術創作，修繕人與衣物之間的關係，同時縫出可持續的未來。',
        簡:'林蔚彦是FashionClinic by T的创办人，涉猎时装工业里多个范畴，由时装杂誌编辑到国际品牌设计师，曾担任法国品牌agnès b.设计经理。Toby Crispy觉醒快速时尚文化需要「治疗」，自2013年起，透过与不同的国际品牌、企业、环保及艺术单位合作，推出再生时装设计、举办工作坊、艺术展览、织物装置等，为衣物创作带来跨越时尚之外的好处，延长织物的寿命和传承手艺。\r\n\r\n2016年她发起FashionClinic，并在2020年添加了「byT」 强调衣物中的时间(Time)和故事(Tale)的意义；以日常衣物代替时钟，记录时间和故事。同时作为独立策展人，Toby通过她的慢针黹牧民(SLOW STiTCH NOMAD)艺术项目将个人和社区疗癒的空间融入到永续设计的实践中，以一针一线的「慢 针黹」艺术创作，修缮人与衣物之间的关係，同时缝出可持续的未来。',
    },


    post8Title:{
        EN:'Li Hang',
        繁:'李萬鏗',
        簡:'李万铿',
    },
    post8Subtitle:{
        EN:'Artwork: (1) Flowing Light, (2) Sowing Light, (3) Weaving Twilight',
        繁:'作品：(1)源流、(2)植光、(3)織夕',
        簡:'作品：(1)源流、(2)植光、(3)织夕',
    },
    post8Content:{
        EN:'Hang Li@ Sandwishes Studio, a Taiwanese artist, has created different works based in Taiwan.\r\n\r\nEstablished in 2012, Sandwishes Studio accumulates creative works, operational experience, and information through diverse projects, fostering inclusive social practice. Through art and design, it explores and responds to social issues, offering collaboration in curation, artistic creation, and inclusive services to cultivate a cultural environment accessible and engaging for all.',
        繁:'藝術家李萬鏗@三明治工，在台灣從事創作相關工作。\r\n\r\n三明治工成立於2012年，透過多元的計劃，累積創作，⼯作與資訊的⽂化參與內容，推進共融的社會實踐。通過藝術與設計，探索並回應社會議題，提供策展、藝術創作、共融服務等合作⽅式，打造讓每個⼈能夠理解與參與的⽂化環境。',
        簡:'艺术家李万铿@三明治工，在台湾从事创作相关工作。\r\n\r\n三明治工成立于2012年，透过多元的计划，累积创作，⼯作与资讯的⽂化参与内容，推进共融的社会实践。通过艺术与设计，探索并回应社会议题，提供策展、艺术创作、共融服务等合作⽅式，打造让每个⼈能够理解与参与的⽂化环境。',
    },


    post9Title:{
        EN:'Wan Kin On',
        繁:'温健安',
        簡:'温健安',
    },
    post9Subtitle:{
        EN:'Artwork: (1) Luminescence Unicorn, (2) Traditional Paper Crafting',
        繁:'作品：(1)夜光麒麟、(2)傳統燈飾紮作',
        簡:'作品：(1)夜光麒麟、(2)传统灯饰扎作',
    },
    post9Content:{
        EN:'Master Wan Kin On, a villager of Yung Shue Au Village, grew up in the walled village. During his childhood, he began learning the lion dance and martial arts under the tutelage of a master within the village. Master Wan has taught the lion dance, dragon dance, lion arts and martial arts for many years, currently instructing over several hundred students. He has been involved in the traditional crafting of lion dance costumes for many years. He maintains traditional craftsmanship while also innovating, incorporating elements such as night lighting and village motifs into his creations.',
        繁:'温健安師傅，榕樹凹村村民，自小在圍村長大，童年時得在村內的師傅指教下開始學習麒麟、武術。温師傅多年教授麒麟、龍藝、獅藝及武術， 現時教授學員超過數百人。他多年從事傳統麒麟紥作，利用「紮」、「撲」、「寫」、「裝」步驟製作麒麟，保存傳統手藝，亦推陳出新，融合夜光、村落元素於創作中。',
        簡:'温健安师傅，榕树凹村村民，自小在围村长大，童年时得在村内的师傅指教下开始学习麒麟、武术。温师傅多年教授麒麟、龙艺、狮艺及武术， 现时教授学员超过数百人。他多年从事传统麒麟紥作，利用「扎」、「扑」、「写」、「装」步骤製作麒麟，保存传统手艺，亦推陈出新，融合夜光、村落元素于创作中。',
    },


    post10Title:{
        EN:'Ada Chan',
        繁:'陳盈敏',
        簡:'陈盈敏',
    },
    post10Subtitle:{
        EN:'Artwork: (1) Glint in the Darkness, (2) Homeland Revive',
        繁:'作品：(1)浮光夜頌、(2)重塑萬家',
        簡:'作品：(1)浮光夜颂、(2)重塑万家',
    },
    post10Content:{
        EN:'Ada Chan is a local based design researcher and artist, who has a profound passion for observing everyday life. She creates engaging stories through her work, aiming to raise awareness and inspire positive change for a more sustainable future.\r\n\r\nAda studied abroad in Norway to learn about sustainable design and actively participated in local community art projects during her studies. This experience allowed her to blend her artistic approach with design. With her skills in design, a keen eye for aesthetics, and knowledge of materials. Ada aims to connect with diverse audiences through various media to promote environmental conservation and sustainable ideas in daily practice.',
        繁:'香港本地設計研究員及藝術工作者，熱愛觀察日常生活，擅長透過立體作品和空間設計來敍述有趣的故事。曾多次展出與社區藝術和環境議題相關的藝術裝置作品。及後遠赴北歐留學，主修環保物料研究。在留學期間，她積極參與當地的社區藝術項目，將藝術創作與參與式設計相結合。她希望憑藉自身的設計專業、美學認知和物料應用經驗，在不同的創作及展示平台上與觀眾進行交流互動，從而推動環境保育和社區可持續發展的理念。',
        簡:'香港本地设计研究员及艺术工作者，热爱观察日常生活，擅长透过立体作品和空间设计来敍述有趣的故事。曾多次展出与社区艺术和环境议题相关的艺术装置作品。及后远赴北欧留学，主修环保物料研究。在留学期间，她积极参与当地的社区艺术项目，将艺术创作与参与式设计相结合。她希望凭藉自身的设计专业、美学认知和物料应用经验，在不同的创作及展示平台上与观众进行交流互动，从而推动环境保育和社区可持续发展的理念。',
    },

    post2Img:{
        EN:`/images/artinstallations/p2.jpg`,
        繁:`/images/artinstallations/p2.jpg`,
        簡:`/images/artinstallations/p2.jpg`,
    },
    post3Img:{
        EN:`/images/artinstallations/p3.jpg`,
        繁:`/images/artinstallations/p3.jpg`,
        簡:`/images/artinstallations/p3.jpg`,
    },
    post4Img:{
        EN:`/images/artinstallations/p4.jpg`,
        繁:`/images/artinstallations/p4.jpg`,
        簡:`/images/artinstallations/p4.jpg`,
    },
    post5Img:{
        EN:`/images/artinstallations/p5.jpeg`,
        繁:`/images/artinstallations/p5.jpeg`,
        簡:`/images/artinstallations/p5.jpeg`,
    },
    post6Img:{
        EN:`/images/artinstallations/p6.png`,
        繁:`/images/artinstallations/p6.png`,
        簡:`/images/artinstallations/p6.png`,
    },
    post7Img:{
        EN:`/images/artinstallations/p7.jpg`,
        繁:`/images/artinstallations/p7.jpg`,
        簡:`/images/artinstallations/p7.jpg`,
    },
    post8Img:{
        EN:`/images/artinstallations/p8.jpeg`,
        繁:`/images/artinstallations/p8.jpeg`,
        簡:`/images/artinstallations/p8.jpeg`,
    },
    post9Img:{
        EN:`/images/artinstallations/p9.jpg`,
        繁:`/images/artinstallations/p9.jpg`,
        簡:`/images/artinstallations/p9.jpg`,
    },
    post10Img:{
        EN:`/images/artinstallations/p10.jpeg`,
        繁:`/images/artinstallations/p10.jpeg`,
        簡:`/images/artinstallations/p10.jpeg`,
    },

}