export const screeningContent = {
    titleL:{
        EN:'Sight @ Kuk Po',
        繁:'色@谷埔',
        簡:'色@谷埔',
    },
    titleR:{
        EN:'Screening Session',
        繁:'分享會',
        簡:'分享会',
    },
    post1Title:{
        EN:'Moonlight Storyteller - Screening Session',
        繁:'月下說書人——放映分享會',
        簡:'月下说书人——放映分享会',
    },
    post1Text:{
        EN:[`Under the soft glow of the moon, immerse yourself in the stories of Kuk Po with a screening of the documentary "Homecoming", which chronicles the village’s transformation over the past three years. Villagers themselves take on the role of storytellers, sharing cherished memories. This is more than just a visit; it's a chance to become part of Kuk Po's legacy—exploring, contributing, and becoming a new "Kuk Po villager".`],
        繁:['⽉光下，述說⾕埔，放映紀錄片「歸去來兮」，⾒證⾕埔三年來的轉變。村⺠亦充當說書⼈，帶來昔⽇的點滴。','藉著重回往⽇的⾕埔的感覺，⼤眾不只是旅客，也是參與者、探索者，參與「傳承」，成就新的「⾕埔⼈」。'],
        簡:['⽉光下，述说⾕埔，放映纪录片「归去来兮」，⾒证⾕埔三年來的转变。村⺠亦充当说书⼈，带来昔⽇的点滴。','藉着重回往⽇的⾕埔的感觉，⼤众不只是旅客，也是参与者、探索者，参与「传承」，成就新的「⾕埔⼈」。'],
    },
    post1Date1:{
        EN:'19/1(Sun), 25/1(Sat), 26/1(Sun)',
        繁:'19/1(日)、25/1(六)、26/1(日)',
        簡:'19/1(日)、25/1(六)、26/1(日)',
    },
    post1Time1:{
        EN:'5:00 pm – 7:00 pm',
        繁:'下午5:00 – 7:00',
        簡:'下午5:00 – 7:00',
    },
    post1Loca1:{
        EN:'Grassland outside LEE’s Mansion, Tin Sum',
        繁:'田心李氏大宅外草地',
        簡:'田心李氏大宅外草地',
    },
}